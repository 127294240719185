<ip-toolbar
  title="{{ 'cookies_privacy.title' | translate | uppercase }}"
  color="secondary"
  arrowColor="text-secondary"
  textColor="text-secondary"
></ip-toolbar>

<!-- <h1>{{ 'cookies_privacy.title' | translate }}</h1> -->
<div class="container-fluid mt-2">
  <h4>{{ 'cookies_privacy.introduction_title' | translate | uppercase }}</h4>
  <p>{{ 'cookies_privacy.introduction_p1' | translate }}</p>
  <p>{{ 'cookies_privacy.introduction_p2' | translate }}</p>
  <p>{{ 'cookies_privacy.introduction_p3' | translate }}</p>
  <h4>{{ 'cookies_privacy.use_of_cookies_title' | translate | uppercase }}</h4>
  <p>{{ 'cookies_privacy.cookies_p1' | translate }}</p>
  <h4>{{ 'cookies_privacy.type_cookies_title' | translate | uppercase }}</h4>
  <p>{{ 'cookies_privacy.type_cookies_p1' | translate }}</p>
  <h5>{{ 'cookies_privacy.advertising_cookies_subtitle' | translate }}</h5>
  <p>{{ 'cookies_privacy.advertising_cookies_p1' | translate }}</p>
  <h5>{{ 'cookies_privacy.analytics_cookies_subtitle' | translate }}</h5>
  <p>{{ 'cookies_privacy.analytics_cookies_p1' | translate }}</p>
  <h5>{{ 'cookies_privacy.our_cookies_subtitle' | translate }}</h5>
  <p>{{ 'cookies_privacy.our_cookies_p1' | translate }}</p>
  <h5>{{ 'cookies_privacy.personalization_cookies_subtitle' | translate }}</h5>

  <p>{{ 'cookies_privacy.personalization_cookies_p1' | translate }}</p>
  <h5>{{ 'cookies_privacy.security_cookies_subtitle' | translate }}</h5>
  <p>{{ 'cookies_privacy.security_cookies_p1' | translate }}</p>
  <h5>{{ 'cookies_privacy.site_management_cookies_subtitle' | translate }}</h5>
  <p>{{ 'cookies_privacy.site_management_cookies_p1' | translate }}</p>
  <h5>{{ 'cookies_privacy.third_party_cookies_subtitle' | translate }}</h5>
  <p>{{ 'cookies_privacy.third_party_cookies_p1' | translate }}</p>

  <h4>{{ 'cookies_privacy.control_cookies_title' | translate | uppercase }}</h4>
  <p>{{ 'cookies_privacy.control_cookies_p1' | translate }}</p>

  <div class="row m-5">
    <table>
      <tr>
        <td>{{ 'cookies_privacy.control_cookies_links.safari' | translate }}</td>
        <td>{{ 'cookies_privacy.control_cookies_links.chrome' | translate }}</td>
        <td>{{ 'cookies_privacy.control_cookies_links.edge' | translate }}</td>
        <td>{{ 'cookies_privacy.control_cookies_links.explorer' | translate }}</td>
        <td>{{ 'cookies_privacy.control_cookies_links.firefox' | translate }}</td>
      </tr>
      <tr>
        <td>{{ 'cookies_privacy.control_cookies_links.opera' | translate }}</td>
        <td>{{ 'cookies_privacy.control_cookies_links.android' | translate }}</td>
        <td>{{ 'cookies_privacy.control_cookies_links.blackberry' | translate }}</td>
        <td>{{ 'cookies_privacy.control_cookies_links.iphone_chrome' | translate }}</td>
        <td>{{ 'cookies_privacy.control_cookies_links.iphone_safari' | translate }}</td>
      </tr>
    </table>
  </div>

  <p>
    {{ 'cookies_privacy.control_cookies_links.additions' | translate }}:
    <a href="https://optout.networkadvertising.org" target="_blank">{{
      'cookies_privacy.control_cookies_links.additions_link' | translate
    }}</a>
  </p>
  <h4>{{ 'cookies_privacy.other_tracking_technologies_title' | translate | uppercase }}</h4>
  <p>{{ 'cookies_privacy.other_tracking_technologies_p1' | translate }}</p>

  <h4>{{ 'cookies_privacy.privacy_policy_title' | translate | uppercase }}</h4>
  <p>{{ 'cookies_privacy.privacy_policy_p1' | translate }}</p>
  <p>{{ 'cookies_privacy.privacy_policy_p2' | translate }}</p>

  <h5>{{ 'cookies_privacy.information_collection_subtitle' | translate }}</h5>
  <p>{{ 'cookies_privacy.information_collection_p1' | translate }}</p>

  <h5>{{ 'cookies_privacy.log_data_subtitle' | translate }}</h5>
  <p>{{ 'cookies_privacy.log_data_p1' | translate }}</p>
  <p>{{ 'cookies_privacy.log_data_p2' | translate }}</p>

  <h5>{{ 'cookies_privacy.communitactions_subtitle' | translate }}</h5>
  <p>{{ 'cookies_privacy.communitactions_p1' | translate }}</p>

  <h5>{{ 'cookies_privacy.security_subtitle' | translate }}</h5>
  <p>{{ 'cookies_privacy.security_p1' | translate }}</p>

  <h4>{{ 'cookies_privacy.changes_policity_title' | translate | uppercase }}</h4>
  <p>{{ 'cookies_privacy.changes_policity_p1' | translate }}</p>
  <p>{{ 'cookies_privacy.changes_policity_p2' | translate }}</p>
  <p>{{ 'cookies_privacy.changes_policity_p3' | translate }}</p>

  <h4>{{ 'cookies_privacy.contact_us_title' | translate | uppercase }}</h4>
  <p>{{ 'cookies_privacy.contact_us_p1' | translate }}</p>
</div>
