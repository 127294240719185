
export const MAP_LANGUAGES = new Map<string, any>([
    ['es', {country: 'ES', lang: 'Español'}],
    ['it', {country: 'IT', lang: 'Italiano'}],
    ['en', {country: 'GB', lang: 'English'}],
  ]);

export class Utils {
    public static randomColor(brightness: number): string {
        return '#' + this.randomChannel(brightness) + this.randomChannel(brightness) + this.randomChannel(brightness);
    }

    public static isInputKey(event: KeyboardEvent) {
        return (event?.key?.length === 1 || event.key === 'Backspace' || event.key === 'Delete');
    }

    private static randomChannel(brightness: number): string {
        const r = 255 - brightness;
        // tslint:disable-next-line: no-bitwise
        const n = 0 | ((Math.random() * r) + brightness);
        const s = n.toString(16);
        return (s.length === 1) ? '0' + s : s;
    }

    public static getError(error): any {
        return error ? (error.error ? (error.error.data ? error.error.data.error : error.message) : error.message) : '';
    }

    public static caseWord(word: string): string {
        if (!word) { return word; }
        return word[0].toUpperCase() + word.substr(1).toLowerCase();
    }

}

export class EnumReflection {
    private static REGEXP: RegExp = /^[0-9]+$/g;

    private static isString<T>(name: string): boolean {
        if (name.match(this.REGEXP)) {
            return false;
        }

        return true;
    }

    public static getNames<T>(object: T): Array<string> {
        const result = new Array<string>();

        for (const name in object) {
            if (this.isString(name)) {
                result.push(name);
            }
        }

        return result;
    }

    public static getValues<T>(object: T): Array<string | number> {
        const result = new Array<string | number>();

        for (const name in object) {
            if (this.isString(name)) {
                result.push(<any>object[name]);
            }
        }
        return result;
    }
}
