<span class="created-by">
  &copy; {{ year }}
  <b
    ><a href="https://www.infoport.es/" target="_blank">Infoport </a> &

    <a href="https://www.fundacion.valenciaport.com/" target="_blank">Fundación Valenciaport </a></b
  >
</span>
<!-- <div class="socials"> -->
<div>
  <b
    >Infoport
    <a href="https://twitter.com/infoport_ipv" target="_blank" class="ion ion-social-twitter">
      <nb-icon icon="twitter-outline"></nb-icon>
    </a>
    <a href="https://es.linkedin.com/company/infoport" target="_blank" class="ion ion-social-linkedin"
      ><nb-icon icon="linkedin-outline"></nb-icon> </a
  ></b>
  <br />
  <b>Fundación Valenciaport </b>
  <a href="https://www.linkedin.com/company/fundaci-n-valenciaport/" target="_blank" class="ion ion-social-linkedin"
    ><nb-icon icon="facebook-outline"></nb-icon>
  </a>
  <a href="https://www.linkedin.com/company/fundaci-n-valenciaport/" target="_blank" class="ion ion-social-twitter">
    <nb-icon icon="twitter-outline"></nb-icon>
  </a>
  <a href="https://twitter.com/fvalenciaport/" target="_blank" class="ion ion-social-linkedin"
    ><nb-icon icon="linkedin-outline"></nb-icon>
  </a>
</div>
