import { LOCALE_ID, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {
  NbThemeModule,
  NbSidebarModule,
  NbMenuModule,
  NbDatepickerModule,
  NbDialogModule,
  NbWindowModule,
  NbToastrModule,
  NbChatModule
} from '@nebular/theme';
import { SharedModule } from './shared/shared.module';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateLoader, TranslateStore } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { NbAuthJWTInterceptor, NbAuthModule, NB_AUTH_TOKEN_INTERCEPTOR_FILTER } from '@nebular/auth';
import { NbSecurityModule, NbRoleProvider } from '@nebular/security';

import { TranslateConfigService } from './services/translate-config.service';

// MODULOS
import { AuthGuardService } from './auth/services/auth-guard.service';
import { FormsModule } from '@angular/forms';

// AUTENTIFICACIÓN Y SEGURIDAD
import { AuthOptions } from './auth/config/auth-options';
import { ROLES } from './shared/utils/roles';

import { RoleProvider } from './auth/services/auth-role-provider.service';
import { UserRestApiService } from './services/apis/user-rest-api.service';
import { AuthTermsService } from './auth/services/auth-terms.service';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { CustomMatPaginatorIntlService } from './shared/services/custom-mat-paginator-intl.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

import localeEs from '@angular/common/locales/es';
import localeEn from '@angular/common/locales/en';
import localeIt from '@angular/common/locales/it';
import { SplitPipe } from './shared/pipes/split.pipe';

registerLocaleData(localeEs, 'es');
registerLocaleData(localeEn, 'en');
registerLocaleData(localeIt, 'it');

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

const NB_FOR_ROOT_MODULES = [
  NbSidebarModule.forRoot(),
  NbThemeModule.forRoot(),
  NbMenuModule.forRoot(),
  NbDatepickerModule.forRoot(),
  NbDialogModule.forRoot(),
  NbWindowModule.forRoot(),
  NbToastrModule.forRoot(),
  NbSecurityModule.forRoot(ROLES),
  NbChatModule.forRoot({
    messageGoogleMapKey: 'AIzaSyA_wNuCzia92MAmdLRzmqitRGvCF7wCZPY'
  }),
  NbAuthModule.forRoot(AuthOptions)
];

const SERVICES = [AuthGuardService, AuthTermsService, UserRestApiService];

@NgModule({
  imports: [
    ...NB_FOR_ROOT_MODULES,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    MatDatepickerModule,
    // TranslateModule.forChild({
    //   loader: { provide: TranslateLoader, useFactory: HttpLoaderFactory, deps: [HttpClient] }
    // }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => HttpLoaderFactory(http),
        deps: [HttpClient]
      }
    }),
    SharedModule.forRoot(),
    FontAwesomeModule
  ],
  exports: [],
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  providers: [
    ...SERVICES,
    TranslateStore,
    {
      provide: LOCALE_ID,
      deps: [TranslateConfigService],
      useFactory: translateConfigService => translateConfigService.getLanguage()
    },
    {
      provide: NbRoleProvider,
      useClass: RoleProvider
    },
    { provide: NB_AUTH_TOKEN_INTERCEPTOR_FILTER, useValue: () => false },
    { provide: HTTP_INTERCEPTORS, useClass: NbAuthJWTInterceptor, multi: true },
    { provide: MatPaginatorIntl, useClass: CustomMatPaginatorIntlService },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    // { provide: MAT_DATE_LOCALE, useValue: 'en-EN' },
    {
      provide: MAT_DATE_LOCALE,
      deps: [TranslateConfigService],
      useFactory: translateConfigService => translateConfigService.getMatDateLocale()
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS }
  ],
  schemas: [NO_ERRORS_SCHEMA]
})
export class AppModule {}
