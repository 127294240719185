// // import { NbMenuItem } from '@nebular/theme';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from './interface/menu-item';

export class MenuItems {
  MENU_ITEMS: MenuItem[] = [
    {
      title: 'Home',
      icon: 'home-outline',
      link: '/settings',
      home: true,
      lang: 'Home',
      data: 'Home'
    },
    {
      title: 'Dashboard',
      icon: 'layout-outline',
      link: '/pages/dashboard',
      lang: 'Dashboard',
      data: 'Dashboard'
    },
    {
      title: 'Locations',
      icon: 'map-outline',
      lang: 'Locations',
      data: 'Locations',
      children: [
        // {
        //   title: 'Maps',
        //   link: '/pages/map',
        //   data: 'Maps',
        //   lang: 'Maps'
        // },
        {
          title: 'List',
          link: '/pages/list',
          data: 'List',
          lang: 'List'
        }
      ]
    },
    {
      title: 'Opciones de acceso',
      icon: 'lock-outline',
      lang: 'Auth',
      data: 'Auth',
      children: [
        {
          title: 'Login',
          link: '/auth/login',
          lang: 'Login',
          data: 'Login'
        },
        // {
        //   title: 'Regístrese',
        //   link: '/auth/register',
        //   lang: 'Register',
        //   data: 'Register'
        // },
        {
          title: 'Olvidé la contraseña',
          link: '/auth/request-password',
          lang: 'RequestPassword',
          data: 'RequestPassword'
        },
        {
          title: 'Logout',
          link: '/auth/logout',
          lang: 'Logout',
          data: 'Logout'
        },
        {
          title: 'Cambiar contraseña',
          link: '/auth/reset-password',
          lang: 'ResetPassword',
          data: 'ResetPassword'
        }
      ]
    }
  ];

  changeTitles(children: any[], trlt: TranslateService) {
    children.forEach(item => {
      if (item.lang) {
        item.title = trlt.instant(`menu.${(item.lang as string).toLowerCase()}`);
      }
      if (item.children) {
        this.changeTitles(item.children, trlt);
      }
    });
  }

  constructor(translate: TranslateService) {
    translate.onLangChange.subscribe(() => {
      this.changeTitles(this.MENU_ITEMS, translate);
    });
  }
}
