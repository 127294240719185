<mat-button-toggle-group
  *ngIf="buttonToggle"
  name="language"
  aria-label="Language"
  [(value)]="language"
  #group="matButtonToggleGroup"
>
  <mat-button-toggle
    *ngFor="let lang of languages.keys()"
    matTooltip="{{ languages.get(lang).lang }}"
    [value]="lang"
    (click)="useLanguage(lang)"
    style="background-image: '{{ base }}assets/flags/flags-iso/flat/24/{{ languages.get(lang).country }}.png';"
  >
    <span><img src="{{ base }}assets/flags/{{ languages.get(lang).country }}.png" /> </span>
  </mat-button-toggle>
</mat-button-toggle-group>

<ng-container *ngIf="!buttonToggle">
  <button mat-button [matMenuTriggerFor]="menu">
    <span><img src="{{ base }}assets/flags/{{ languages.get(language).country }}.png"/></span>
  </button>
  <mat-menu #menu="matMenu">
    <button
      mat-menu-item
      *ngFor="let lang of languages.keys()"
      matTooltip="{{ languages.get(lang).lang }}"
      [value]="lang"
      (click)="useLanguage(lang)"
      style="background-image: '{{ base }}assets/flags/flags-iso/flat/24/{{ languages.get(lang).country }}.png';"
    >
      <span><img src="{{ base }}assets/flags/{{ languages.get(lang).country }}.png" /> {{ languages.get(lang).lang }}</span>
    </button>
  </mat-menu>
</ng-container>
