export const Themes = [
  {
    value: 'default',
    name: 'Light'
  },
  {
    value: 'dark',
    name: 'Dark'
  },
  {
    value: 'cosmic',
    name: 'Cosmic'
  },
  {
    value: 'corporate',
    name: 'Corporate'
  }
];
