import { NbAuthSocialLink } from '@nebular/auth';

const socialLinks: NbAuthSocialLink[] = [];

interface ShowMessages {
  success: boolean;
  error: boolean;
}

interface FormSettings {
  redirectDelay: number;
  strategy?: string;
  showMessages?: ShowMessages;
  socialLinks?: NbAuthSocialLink[];
  rememberMe?: boolean;
  terms?: boolean;
  urlTerms?: string;
}

interface Validation {
  password: ValidationSettings;
  email: ValidationSettings;
  fullName: ValidationSettings;
}

interface ValidationSettings {
  required: boolean;
  minLength?: number;
  maxLength?: number;
}

export const loginSettings: FormSettings = {
  redirectDelay: 500, // delay before redirect after a successful login, while success message is shown to the user
  strategy: 'email', // strategy id key.
  rememberMe: true, // whether to show or not the `rememberMe` checkbox
  showMessages: {
    // show/not show success/error messages
    success: true,
    error: true
  },
  socialLinks // social links at the bottom of a page
};

export const registerSettings: FormSettings = {
  redirectDelay: 12000,
  strategy: 'email',
  terms: true,
  urlTerms: ''
  // showMessages: {
  //   success: true,
  //   error: true
  // },
  // socialLinks
};

export const requestPasswordSettings: FormSettings = {
  redirectDelay: 8000,
  strategy: 'email'
  // showMessages: {
  //   success: true,
  //   error: true
  // },
  // socialLinks
};
export const resetPasswordSettings: FormSettings = {
  redirectDelay: 8000,
  strategy: 'email',
  showMessages: {
    success: true,
    error: true
  },
  socialLinks
};

export const logoutSettings: FormSettings = {
  redirectDelay: 1500,
  strategy: 'email'
};

export const validationSettings: Validation = {
  password: {
    required: true,
    minLength: 4,
    maxLength: 255
  },
  email: {
    required: true
  },
  fullName: {
    required: false,
    minLength: 4,
    maxLength: 255
  }
};
