import { Component, OnInit } from '@angular/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { faCoffee } from '@fortawesome/free-solid-svg-icons';
import { NbAccessChecker } from '@nebular/security';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [
    // {
    //   provide: DateAdapter,
    //   useClass: MomentDateAdapter,
    //   deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    // },
    // { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS }
  ]
})
export class DashboardComponent implements OnInit {
  lang;

  faCoffee = faCoffee;

  emailResponse = 'hector@gmail.com';

  a = 1;
  b = 2;

  isTrue: boolean = false;
  constructor(private accessChecker: NbAccessChecker, private _adapter: DateAdapter<any>) {}

  ngOnInit(): void {
    // PRUEBA PERMISO
    // this.accessChecker.isGranted('read', 'User').subscribe(res => res);
  }

  changeTheme() {
    this.isTrue = !this.isTrue;
  }

  changeAdapter() {
    this._adapter.setLocale('es');
  }

  french() {
    this._adapter.setLocale('fr');
  }

  england() {
    this._adapter.setLocale('en');
  }
}
