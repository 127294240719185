// MODULOS
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PagesRoutingModule } from './pages-routing.module';
import { SharedModule } from '../shared/shared.module';
import { NbMenuModule } from '@nebular/theme';
import { NbAuthModule } from '@nebular/auth';
import { NbSecurityModule } from '@nebular/security';

// COMPONENTES
import { PagesComponent } from './pages.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { HomeComponent } from './components/home/home.component';
import { MapComponent } from './components/locations/map/map.component';
import { ListComponent } from './components/locations/list/list.component';
import { TermsComponent } from './components/home/terms/terms.component';
import { CookiesPrivacyComponent } from './components/home/cookies-privacy/cookies-privacy.component';
import { ListDetailComponent } from './components/locations/list/list-detail/list-detail.component';

const NB_MODULES = [NbMenuModule, NbSecurityModule, NbAuthModule];

const MODULES = [CommonModule, PagesRoutingModule, SharedModule];
@NgModule({
  declarations: [
    PagesComponent,
    DashboardComponent,
    HomeComponent,
    MapComponent,
    ListComponent,
    TermsComponent,
    CookiesPrivacyComponent,
    ListDetailComponent
  ],
  imports: [...MODULES, ...NB_MODULES]
})
export class PagesModule {}
