import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedRoutingModule } from './shared-routing.module';
import { CapitalizePipe } from '../@theme/pipes/capitalize.pipe';
import { PluralPipe } from '../@theme/pipes/plural.pipe';
import { TimingPipe } from '../@theme/pipes/timing.pipe';
import { NumberWithCommasPipe } from '../@theme/pipes/number-with-commas.pipe';
import { RoundPipe } from '../@theme/pipes/round.pipe';

import {
  NbThemeModule,
  NbLayoutModule,
  NbSidebarModule,
  NbButtonModule,
  NbActionsModule,
  NbSearchModule,
  NbUserModule,
  NbContextMenuModule,
  NbSelectModule,
  NbIconModule,
  NbCardModule,
  NbAlertModule,
  NbCheckboxModule,
  NbInputModule,
  NbRadioModule,
  NbFormFieldModule
} from '@nebular/theme';

import { NbEvaIconsModule } from '@nebular/eva-icons';
import { NbSecurityModule } from '@nebular/security';

import { IpHeaderComponent, IpLayoutComponent, IpFooterComponent, IpSearchComponent } from './components';
import { UserData } from '../@core/data/users';
import { UserService } from '../@core/mock/users.service';
import { LayoutService } from '../@core/utils/layout.service';
import { IpNotFoundComponent } from './components/ip-not-found/ip-not-found.component';

// THEMES NEBULAR
import { DEFAULT_THEME } from '../@theme/styles/theme.default';
import { COSMIC_THEME } from '../@theme/styles/theme.cosmic';
import { CORPORATE_THEME } from '../@theme/styles/theme.corporate';
import { DARK_THEME } from '../@theme/styles/theme.dark';

// ANGULAR MATERIAL
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTreeModule } from '@angular/material/tree';
import { MatDividerModule } from '@angular/material/divider';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule, MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatRippleModule, MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSliderModule } from '@angular/material/slider';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatPaginatorModule } from '@angular/material/paginator';

// FontAwesome
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IconSnackbarComponent } from './components/icon-snackbar/icon-snackbar.component';

import { LanguageComponent } from './components/language/language.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgxFlagPickerModule } from 'ngx-flag-picker';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';

import { GridModule } from '@progress/kendo-angular-grid';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { ScrollViewModule } from '@progress/kendo-angular-scrollview';
import { IpToolbarComponent } from './components/ip-toolbar/ip-toolbar.component';
import { MglTimelineModule } from 'angular-mgl-timeline';
import { IpFilterComponent } from './components/ip-filter/ip-filter.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FilterPipe } from './components/ip-search/filter.pipe';
import { IPV_THEME } from '../@theme/styles/theme.ipv-theme';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { IpLoadingComponent } from './components/ip-loading/ip-loading.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpInterceptorInterceptor } from './interceptors/http-interceptor.interceptor';
import { SplitPipe } from './pipes/split.pipe';

const PIPES = [CapitalizePipe, PluralPipe, RoundPipe, TimingPipe, NumberWithCommasPipe, FilterPipe, SplitPipe];

const NB_MODULES = [
  NbLayoutModule,
  NbUserModule,
  NbActionsModule,
  NbSearchModule,
  NbSidebarModule,
  NbContextMenuModule,
  NbButtonModule,
  NbSelectModule,
  NbIconModule,
  NbEvaIconsModule,
  NbCardModule,
  NbAlertModule,
  NbCheckboxModule,
  NbInputModule,
  NbRadioModule,
  NbUserModule,
  NbSecurityModule,
  NbFormFieldModule
];

const ANGULAR_MATERIAL_MODULES = [
  MatButtonModule,
  MatMenuModule,
  MatRippleModule,
  MatToolbarModule,
  MatIconModule,
  MatSidenavModule,
  MatTreeModule,
  MatDividerModule,
  MatCardModule,
  MatSelectModule,
  MatInputModule,
  MatTabsModule,
  MatProgressSpinnerModule,
  MatCheckboxModule,
  MatDialogModule,
  MatTooltipModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatRadioModule,
  MatListModule,
  MatExpansionModule,
  MatSnackBarModule,
  MatSliderModule,
  MatButtonToggleModule,
  MatPaginatorModule,
  MatTableModule,
  MatMomentDateModule
];

const KENDO_UI_ANGULAR_MODULE = [DropDownsModule, LayoutModule, GridModule, ScrollViewModule];

const COMPONENTS = [
  IpFooterComponent,
  IpHeaderComponent,
  IpNotFoundComponent,
  IpLayoutComponent,
  IpSearchComponent,
  IpToolbarComponent,
  IpFilterComponent,
  IpLoadingComponent,
  IconSnackbarComponent,
  LanguageComponent
];

const EXPORTS_MODULES = [FontAwesomeModule, TranslateModule, MglTimelineModule, ReactiveFormsModule, FormsModule];
const DATA_SERVICES = [{ provide: UserData, useClass: UserService }, LayoutService];

export const NB_CORE_PROVIDERS = [...DATA_SERVICES];

@NgModule({
  imports: [
    CommonModule,
    SharedRoutingModule,
    NgxFlagPickerModule,
    ...EXPORTS_MODULES,
    ...NB_MODULES,
    ...ANGULAR_MATERIAL_MODULES,
    ...KENDO_UI_ANGULAR_MODULE
  ],
  exports: [
    ...EXPORTS_MODULES,
    ...PIPES,
    ...COMPONENTS,
    ...ANGULAR_MATERIAL_MODULES,
    ...NB_MODULES,
    ...KENDO_UI_ANGULAR_MODULE
  ],
  declarations: [...COMPONENTS, ...PIPES, IpLoadingComponent],
  providers: [
    NB_CORE_PROVIDERS,
    {
      provide: MAT_RADIO_DEFAULT_OPTIONS,
      useValue: { color: 'primary' } // primary,accent,warn
    },
    { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorInterceptor, multi: true }
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        ...NbThemeModule.forRoot(
          {
            name: 'ipv-theme'
          },
          [DEFAULT_THEME, COSMIC_THEME, CORPORATE_THEME, DARK_THEME, IPV_THEME]
        ).providers
      ]
    };
  }
}
