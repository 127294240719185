import { Injectable } from '@angular/core';
import { AbstractRestApi } from './abstract-rest-api';
import { HttpClient } from '@angular/common/http';
import { AssetList } from 'src/app/shared/utils/class/asset';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ListEntitiesService extends AbstractRestApi {
  constructor(protected http: HttpClient) {
    super(http);
  }

  public getEntityList(assetList: AssetList): Observable<AssetList> {
    const query = this.generateQuery(assetList);
    return this.http
      .get<AssetList>(environment.baseUrl + environment.endpoints.entities.endpoint + query, this.httpOptions)
      .pipe(retry(1));
  }

  public getEntityListForEntity(
    assetList: AssetList,
    api: 'shipments' | 'stockItems' | 'conveyors' | 'handlingUnits'
  ): Observable<AssetList> {
    const query = this.generateQuery(assetList);
    return this.http
      .get<AssetList>(environment.baseUrl + environment.endpoints[api].endpoint + query, this.httpOptions)
      .pipe(retry(1));
  }
}
