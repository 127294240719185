import { Injectable } from '@angular/core';
import { Observable, timer, Subscription } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { tap, map, filter, catchError, distinct } from 'rxjs/operators';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { NbAuthService, NbAuthJWTToken, NbAuthSimpleToken } from '@nebular/auth';
import { User } from 'src/app/shared/utils/class/user';
import { UserRestApiService } from 'src/app/services/apis/user-rest-api.service';

@Injectable()
export class AuthTermsService implements CanActivate {
  emptyUser: User = { email: '', fullName: '', disabled: false, roles: [], emailSubscriptions: [] };
  _user: BehaviorSubject<User> = new BehaviorSubject(this.emptyUser);

  timerSubscription: Subscription;

  constructor(private authService: NbAuthService, private userService: UserRestApiService, private router: Router) {
    this.authService
      .onTokenChange()
      .pipe(
        filter((token: NbAuthSimpleToken) => token.getOwnerStrategyName() === 'email'),
        distinct((t: any) => t.token)
      )
      .subscribe((token: NbAuthJWTToken) => {
        if (!token) {
          return;
        }
        if (token.isValid()) {
          this.userService
            .getUser()
            .pipe(distinct((u: User) => u.email))
            .subscribe((user: User) => {
              if (user) {
                if (!user.fullName) {
                  user.fullName = user.email;
                }
                if (user.disabled || !user.approved) {
                  user = this.emptyUser;
                  localStorage.removeItem('auth_app_token');
                }
                this.nextUser = user;
              } else {
                this.nextUser = this.emptyUser;
              }
            });
          if (this.timerSubscription) {
            this.timerSubscription.unsubscribe();
          }
          this.timerSubscription = timer(token.getTokenExpDate().getTime() - new Date().getTime() - 5000).subscribe(() => {
            this.authService
              .refreshToken('email', { token })
              .pipe(
                catchError(error => {
                  //                this.logout();
                  throw error;
                })
              )
              .subscribe();
          });
        } else {
          localStorage.removeItem('auth_app_token');
          this.logout();
        }
      });
  }

  set nextUser(user: User) {
    this._user.next(user);
  }

  public clearUser() {
    this.nextUser = this.emptyUser;
  }

  public logout(): void {
    localStorage.removeItem('auth_app_token');
    this.authService.logout('email').subscribe(result => {
      this.userService.userObservable = null;
      this.clearUser();
    });
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.authService.getToken().pipe(
      tap((token: NbAuthJWTToken) => {
        if (!token.isValid()) {
          this.router.navigate(['auth/login'], { queryParams: { url: state.url } });
        }
        if (token.getPayload()) {
          if (!token.getPayload().terms && !state.url.includes('changedTerms')) {
            this.router.navigate([state.url], { queryParams: { changedTerms: true } });
          }
        }
      }),
      map((token: NbAuthJWTToken) => {
        return token.isValid();
      })
    );
  }

  onUserChange(): Observable<User> {
    return this._user.asObservable().pipe(distinct((u: User) => u.email));
  }
}
