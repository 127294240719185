import { Component, Input } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'ip-toolbar',
  templateUrl: './ip-toolbar.component.html',
  styleUrls: ['./ip-toolbar.component.scss']
})
export class IpToolbarComponent {
  @Input() title: string;
  @Input() color: 'primary' | 'secondary' | 'accent' | 'warn';

  @Input() arrowColor:
    | 'text-primary'
    | 'text-secondary'
    | 'text-success'
    | 'text-danger'
    | 'text-warning'
    | 'text-info'
    | 'text-light'
    | 'text-dark'
    | 'text-muted'
    | 'text-white';

  @Input() textColor:
    | 'text-primary'
    | 'text-secondary'
    | 'text-success'
    | 'text-danger'
    | 'text-warning'
    | 'text-info'
    | 'text-light'
    | 'text-dark'
    | 'text-muted'
    | 'text-white';

  constructor(private location: Location) {}

  goBack() {
    this.location.back();
  }
}
