import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { PagesComponent } from './pages.component';
import { AuthGuardService } from '../auth/services/auth-guard.service';
import { HomeComponent } from './components/home/home.component';
import { MapComponent } from './components/locations/map/map.component';
import { ListComponent } from './components/locations/list/list.component';
import { CookiesPrivacyComponent } from './components/home/cookies-privacy/cookies-privacy.component';
import { TermsComponent } from './components/home/terms/terms.component';

const routes: Routes = [
  {
    path: '',
    component: PagesComponent,
    children: [
      { path: 'home', component: HomeComponent },
      { path: 'cookies-privacy', component: CookiesPrivacyComponent },
      { path: 'terms', component: TermsComponent },
      { path: 'dashboard', canActivate: [AuthGuardService], component: DashboardComponent },
      { path: 'map', canActivate: [AuthGuardService], component: MapComponent },
      { path: 'map/:id', canActivate: [AuthGuardService], component: MapComponent },

      { path: 'list', canActivate: [AuthGuardService], component: ListComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule {}
