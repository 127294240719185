import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, distinct } from 'rxjs/operators';

import { NbRoleProvider } from '@nebular/security';

import { AuthGuardService } from './auth-guard.service';
import { User } from '../../shared/utils/class/user';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';

@Injectable()
export class RoleProvider implements NbRoleProvider {
  constructor(private authGuard: AuthGuardService, private authService: NbAuthService) {}

  public getRole(): Observable<string | string[]> {
    return this.authGuard.onUserChange().pipe(
      distinct((user: User) => user.email),
      map(user => (user.roles.length > 0 ? user.roles : ['guest']))
    );
  }

  // DOCUMENTACIÓN NEBULAR
  // getRole(): Observable<string> {
  //   return this.authService.onTokenChange().pipe(
  //     map((token: NbAuthJWTToken) => {
  //       return token.isValid() ? token.getPayload()['role'] : 'guest';
  //     })
  //   );
  // }
}
