import { Injectable } from '@angular/core';
import { ArrayList } from '../shared/components';
import { AssetList } from '../shared/utils/class/asset';

export type TrackableEntityType = 'shipments' | 'stockItems' | 'conveyors' | 'handlingUnits';

@Injectable({
  providedIn: 'root'
})
export class ListService {

  cacheData: AssetList;
  cacheSelectedItem: string;
  cacheTrackableEntityApi: TrackableEntityType;
  cacheAssetList: AssetList;
  private arrayList: ArrayList[];

  constructor() {
    this.arrayList = [
      {
        value: 'Shipment',
        translateKey: 'filter.shipments',
        searchValue: ['search.bl', 'search.booking', 'search.container']
      },
      {
        value: 'Container',
        translateKey: 'filter.containers',
        searchValue: ['search.license_plate']
      },
      {
        value: 'Truck',
        translateKey: 'filter.trucks',
        searchValue: ['search.license_plate']
      },
      {
        value: 'Train',
        translateKey: 'filter.trains',
        searchValue: ['search.license_plate']
      },
      {
        value: 'Vessel',
        translateKey: 'filter.vessels',
        searchValue: ['search.license_plate']
      },
      {
        value: 'Stock',
        translateKey: 'filter.stocks',
        searchValue: ['search.id', 'search.license_plate']
      }
    ];
  }

  getArrayList() {
    return this.arrayList;
  }

  getSearchValue(event: string) {
    return this.arrayList.find(x => x.value === event).searchValue;
  }
}
