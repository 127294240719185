<!-- HEADER-->

<ng-container *ngIf="data.trackableEntityApi !== 'conveyors' && data.trackableEntityApi !== 'handlingUnits'">
  <section [ngClass]="{ sticky: !data.mobile }" class="sticky p-2">
    <div class="header-title-close">
      <h4 mat-dialog-title>
        <span *ngIf="data.entityData.assetType" class="badge bg-primary"
          >{{ data.entityData.assetType | translate | uppercase }}:</span
        >
        {{ data.entityData.shipmentID ? data.entityData.shipmentID : data.entityData.ID }}
      </h4>
      <button mat-raised-button color="primary" [mat-dialog-close]="data.entityData" cdkFocusInitial>
        <span class="material-icons-outlined"> close </span>
      </button>
    </div>

    <div class="row ">
      <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
        <h6>{{ 'trackable_list.from' | translate | titlecase }}</h6>

        <ul class="list-group" *ngFor="let origins of data.entityData.origins">
          <li class="list-group-item d-flex justify-content-between align-items-center">
            {{ origins && origins.locationName ? (origins.locationName | uppercase) : '--'
            }}<span class="badge bg-info" *ngIf="origins.location">{{ origins.location | uppercase }}</span>
          </li>
        </ul>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
        <h6>{{ 'trackable_list.to' | translate | titlecase }}</h6>

        <ul class="list-group" *ngFor="let destinations of data.entityData.destinations">
          <li class="list-group-item d-flex justify-content-between align-items-center">
            {{ destinations && destinations.locationName ? (destinations.locationName | uppercase) : '--'
            }}<span class="badge bg-info" *ngIf="destinations.location">{{ destinations.location | uppercase }}</span>
          </li>
        </ul>
      </div>

      <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
        <h6>{{ 'trackable_list.eta_ata' | translate | uppercase }}</h6>
        <ul class="list-group" *ngFor="let destinations of data.entityData.destinations">
          <li class="list-group-item d-flex justify-content-between align-items-center">
            {{ destinations && destinations.eta ? (destinations.eta | date: 'dd/MM/yyyy') : '--'
            }}<span class="badge bg-info" *ngIf="destinations.eta">{{ destinations.eta | date: 'hh:mm' }}</span>
          </li>
        </ul>
      </div>

      <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
        <h6>{{ 'trackable_list.last_location' | translate | titlecase }}</h6>
        <ul class="list-group">
          <li class="list-group-item d-flex justify-content-between align-items-center">
            {{
              data.entityData.lastTrackableEvent && data.entityData.lastTrackableEvent.location
                ? (data.entityData.lastTrackableEvent.location.locationName | uppercase)
                : '-'
            }}
            <span
              class="badge bg-info"
              *ngIf="
                data.entityData.lastTrackableEvent &&
                data.entityData.lastTrackableEvent.location &&
                data.entityData.lastTrackableEvent.location.location
              "
            >
              {{ data.entityData.lastTrackableEvent.location.location | uppercase }}</span
            >
          </li>
        </ul>
      </div>
    </div>
    <!-- SUBMIT-->
    <div mat-dialog-actions class="m-2">
      <button mat-raised-button (click)="showInMap()" translate>{{ 'dialog.show_in_map' | translate }}</button>
      <!-- <button mat-raised-button color="primary" [mat-dialog-close]="data.entityData" cdkFocusInitial>
        {{ 'dialog.close' | translate }}
      </button> -->
    </div>
    <!-- END SUBMIT-->
  </section>
</ng-container>

<ng-container *ngIf="data.trackableEntityApi === 'conveyors' || data.trackableEntityApi === 'handlingUnits'">
  <section [ngClass]="{ sticky: !data.mobile }" class="sticky p-2">
    <div class="header-title-close">
      <h4 mat-dialog-title>
        <span *ngIf="data.entityData.assetType" class="badge bg-primary"
          >{{ data.entityData.assetType | translate | uppercase }}
        </span>
        <!--RESTO-->
        <span *ngIf="!data.entityData.transportUnitID && !data.entityData.conveyorID">{{
          data.entityData.shipmentID ? data.entityData.shipmentID : data.entityData.ID
        }}</span>

        <!--CAMIONES-->
        <span *ngIf="data.entityData.conveyorID"> {{ data.entityData.name | split: data.entityData.conveyorID }} </span>

        <!--CONTENEDORES-->
        <span *ngIf="data.entityData.transportUnitID">
          <span class="badge bg-primary">{{ 'trackable_list.container' | translate | uppercase }} </span
          >{{ data.entityData.transportUnitID | split: data.entityData.parentEntityID }}</span
        >
      </h4>
      <button mat-raised-button color="primary" [mat-dialog-close]="data.entityData" cdkFocusInitial>
        <span class="material-icons-outlined"> close </span>
      </button>
    </div>

    <div class="row pb-1">
      <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
        <h6>{{ 'trackable_list.from' | translate | titlecase }}</h6>
        <p>
          {{
            data.entityData.origin && data.entityData.origin.locationName
              ? (data.entityData.origin.locationName | uppercase)
              : '-'
          }}<span class="badge bg-info" *ngIf="data.entityData.origin && data.entityData.origin.location">{{
            data.entityData.origin.location | uppercase
          }}</span>
        </p>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
        <h6>{{ 'trackable_list.to' | translate | titlecase }}</h6>
        <p>
          {{
            data.entityData.destination && data.entityData.destination.locationName
              ? (data.entityData.destination.locationName | uppercase)
              : '-'
          }}

          <span class="badge bg-info" *ngIf="data.entityData.destination && data.entityData.destination.location">{{
            data.entityData.destination.location | uppercase
          }}</span>
        </p>
      </div>

      <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
        <h6>{{ 'trackable_list.eta_ata' | translate | titlecase }}</h6>
        <p>
          {{
            data.entityData.destination && data.entityData.destination.eta
              ? (data.entityData.destination.eta | date: 'dd/MM/yyyy')
              : '-'
          }}
          <span class="badge bg-info" *ngIf="data.entityData.destination && data.entityData.destination.eta">
            {{ data.entityData.destination.eta | date: 'hh:mm' }}</span
          >
        </p>
      </div>

      <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
        <h6>{{ 'trackable_list.last_location' | translate | titlecase }}</h6>
        <!-- <p>{{ data.entityData.lastLocation | uppercase }}</p> -->
        <p>
          {{
            data.entityData.lastTrackableEvent && data.entityData.lastTrackableEvent.location
              ? (data.entityData.lastTrackableEvent.location.locationName | uppercase)
              : '-'
          }}
          <span class="badge bg-info" *ngIf="data.entityData.lastTrackableEvent && data.entityData.lastTrackableEvent.location">
            {{ data.entityData.lastTrackableEvent.location.location | uppercase }}</span
          >
        </p>
      </div>
    </div>
    <!-- SUBMIT-->
    <div mat-dialog-actions class="m-2">
      <button mat-raised-button (click)="showInMap()" translate>{{ 'dialog.show_in_map' | translate }}</button>
      <!-- <button mat-raised-button color="primary" [mat-dialog-close]="data.entityData" cdkFocusInitial>
        {{ 'dialog.close' | translate }}
      </button> -->
    </div>
    <!-- END SUBMIT-->
  </section>
</ng-container>

<!--END HEADER-->

<!-- LIST EVENTS-->
<mgl-timeline [toggle]="toggle" [alternate]="alternate" [side]="side">
  <mgl-timeline-entry *ngFor="let event of data.listEvents; let i = index">
    <mgl-timeline-entry-header (click)="onHeaderClick($event)">
      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
          <h6>{{ 'trackable_list.date_time' | translate | titlecase }}</h6>
          <p>
            {{ event.performedTime ? (event.performedTime | date: 'dd/MM/yyyy, HH:mm ' | uppercase) : '--/--/----   --:--' }}
          </p>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
          <h6>{{ 'trackable_list.place_activity' | translate | titlecase }}</h6>
          <p>
            {{ event.location && event.location.locationName ? (event.location.locationName | titlecase) : '--' }}

            <span class="badge bg-info" *ngIf="event.location && event.location.location">{{
              event.location.location | uppercase
            }}</span>
          </p>
        </div>

        <div class="col-sm-12 col-md-12 col-lg-2 col-xl-2">
          <h6>{{ 'trackable_list.status' | translate | titlecase }}</h6>
          <span *ngIf="event.eventType === 'finished'" class="badge bg-success">{{ event.eventType | titlecase }}</span>
          <span
            *ngIf="
              event.eventType === 'delayed' ||
              event.eventType === 'stopped' ||
              event.eventType === 'detained' ||
              event.eventType === 'cancelled'
            "
            class="badge bg-danger"
            >{{ event.eventType | titlecase }}</span
          >
          <span *ngIf="event.eventType === 'changed' || event.eventType === 'stockedOut'" class="badge bg-warning text-dark ">{{
            event.eventType | titlecase
          }}</span>

          <span
            *ngIf="
              event.eventType !== 'finished' &&
              event.eventType !== 'delayed' &&
              event.eventType !== 'stopped' &&
              event.eventType !== 'detained' &&
              event.eventType !== 'cancelled' &&
              event.eventType !== 'changed' &&
              event.eventType !== 'stockedOut'
            "
            class="badge bg-primary"
            >{{ event.eventType | titlecase }}</span
          >
        </div>

        <div class="col-sm-12 col-md-12 col-lg-2 col-xl-2">
          <h6>{{ 'trackable_list.transport' | translate | titlecase }}</h6>
          <ng-container *ngIf="event.references">
            <ng-container *ngFor="let reference of event.references">
              <span *ngIf="reference.type === 'licensePlate'" class="badge bg-secondary">{{
                reference.value ? reference.value : '--'
              }}</span>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="!event.references">
            <span class="badge bg-secondary"> {{ 'trackable_list.no_info' | translate }} </span>
          </ng-container>
        </div>

        <div class="col-sm-12 col-md-12 col-lg-2 col-xl-2">
          <h6>{{ 'trackable_list.transport_unit' | translate | titlecase }}</h6>
          <ng-container *ngIf="event.references">
            <ng-container *ngFor="let reference of event.references">
              <span *ngIf="reference.type === 'transportUnit'" class="badge bg-secondary">{{
                reference.value ? reference.value : '--'
              }}</span>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="!event.references">
            <span class="badge bg-secondary"> {{ 'trackable_list.no_info' | translate }} </span>
          </ng-container>
        </div>
      </div>
    </mgl-timeline-entry-header>
    <mgl-timeline-entry-content
      [expandAnimationTiming]="contentAnimation ? '200ms ease' : '0ms'"
      [collapseAnimationTiming]="contentAnimation ? '100ms ease' : '0ms'"
    >
      <div></div>
    </mgl-timeline-entry-content>
    <mgl-timeline-entry-dot
      [class]="i === 0 ? 'dashed' : 'blue'"
      [size]="size"
      (click)="onDotClick($event)"
      [expandAnimationTiming]="dotAnimation ? '200ms ease' : '0ms'"
      [collapseAnimationTiming]="dotAnimation ? '100ms ease' : '0ms'"
    ></mgl-timeline-entry-dot>
  </mgl-timeline-entry>
</mgl-timeline>
<!-- END LIST EVENTS-->
