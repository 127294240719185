import { Component, Input, OnInit } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';

@Component({
  selector: 'ip-loading',
  templateUrl: './ip-loading.component.html',
  styleUrls: ['./ip-loading.component.scss']
})
export class IpLoadingComponent {
  @Input() diameter: number = 44;
  @Input() color: ThemePalette = 'primary';
  @Input() mode: ProgressSpinnerMode = 'indeterminate';
  @Input() value: string = '50';

  constructor() {}
}
