import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';
import { Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, takeUntil, tap } from 'rxjs/operators';
import { UserData } from 'src/app/@core/data/users';
import { LayoutService } from 'src/app/@core/utils/layout.service';

import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthGuardService } from '../../../auth/services/auth-guard.service';
import { Router } from '@angular/router';
import { Themes } from '../../utils/themes';

@Component({
  selector: 'ip-header',
  templateUrl: './ip-header.component.html',
  styleUrls: ['./ip-header.component.scss']
})
export class IpHeaderComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user;
  usuario;
  lang;

  currentTheme = 'default';
  defaultTheme: boolean = true;

  userMenu = [{ title: 'Profile' }, { title: 'Logout' }];

  get Themes() {
    return Themes;
  }

  constructor(
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private userService: UserData,
    private layoutService: LayoutService,
    private breakpointService: NbMediaBreakpointsService,
    private authService: NbAuthService,
    private authGuard: AuthGuardService,
    private translateConfigService: TranslateConfigService,
    private translate: TranslateService,
    private router: Router
  ) {
    this.authService.onTokenChange().subscribe((token: NbAuthJWTToken) => {
      // console.log('token:', token);
      // console.log('valid', token.isValid());
      // console.log('getPayLoad()', token.getPayload());
      if (token.isValid()) {
        // this.usuario = token.getPayload(); // here we receive a payload from the token and assigns it to our `user` variable
      }
    });
  }

  ngOnInit() {
    this.authGuard
      .onUserChange()
      .pipe(distinctUntilChanged())
      .subscribe(user => {
        // console.log('AuthGuard - onUserChange() -', user);
        this.usuario = user;
        this.usuario.picture = 'assets/images/icon_image.png'; // Temporal hasta que el back nos devuelva la imagen del usuario
      });

    this.menuService
      .onItemClick()
      .pipe(
        tap(res => console.log(res)),
        map(menuItem => menuItem.item.title)
      )
      .subscribe(menuItem => {
        console.log('menuItem', menuItem);
        if (menuItem === 'Profile') {
          window.alert('Profile');
          // return;
        } else if (menuItem === 'Logout') {
          this.router.navigate(['/auth/logout']);
        }
      });
    // this.userService
    //   .getUsers()
    //   .pipe(takeUntil(this.destroy$))
    //   .subscribe((users: any) => {
    //     console.log('userService getUsers()', users);
    //     this.user = users.nick;
    //   });

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService
      .onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$)
      )
      .subscribe((isLessThanXl: boolean) => (this.userPictureOnly = isLessThanXl));

    this.themeService
      .onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$)
      )
      .subscribe(themeName => (this.currentTheme = themeName));
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme() {
    this.defaultTheme = !this.defaultTheme;
    this.themeService.changeTheme(this.defaultTheme ? 'corporate' : 'dark');
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();
    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  login() {
    this.router.navigateByUrl('/auth/login');
  }
}
