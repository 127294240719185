import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatButtonToggleGroup } from '@angular/material/button-toggle';
import { ArrayList } from '../../utils/interface/ip-filter';

@Component({
  selector: 'ip-filter',
  templateUrl: './ip-filter.component.html',
  styleUrls: ['./ip-filter.component.scss']
})
export class IpFilterComponent implements OnInit {
  @ViewChild('group', { static: false })
  public group: MatButtonToggleGroup;

  @Output() selected: EventEmitter<string> = new EventEmitter<string>();
  @Output() disabledFilter: EventEmitter<void> = new EventEmitter<void>();
  @Input() arrayList: ArrayList[];
  @Input() selectedItem: string;
  @Input() disabled: boolean = false;

  selectedValue: string;

  constructor() {}

  ngOnInit() {
    this.selectedValue = this.checkSelectedValue(this.selectedItem);
  }

  radioChange(event) {
    this.selectedValue = this.checkSelectedValue(event.value);
    this.selected.emit(event.value);
  }

  clearSelectedItem() {
    if (this.disabled) {
      return;
    }
    this.selectedItem = null;
    this.group.value = null;
    this.disabledFilter.emit();
  }

  checkSelectedValue(selection: string) {
    const select = this.arrayList.find(x => x.value === selection);
    return select ? select.translateKey : null;
  }
}
