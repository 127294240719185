import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './auth/services/auth-guard.service';
import { PagesModule } from './pages/pages.module';

export const routes: Routes = [
  {
    path: 'pages',
    loadChildren: () => import('../app/pages/pages.module').then(m => PagesModule)
  },
  { path: 'auth', loadChildren: () => import('../app/auth/auth.module').then(m => m.NgxAuthModule) },
  { path: '', redirectTo: 'pages/home', pathMatch: 'full' },
  { path: '**', redirectTo: 'pages/home' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
