declare const L: any;
import { Injectable } from '@angular/core';
import { MarkerClusterGroup } from 'leaflet.markercluster';
import { Location } from '../pages/models/BiTAModel';

@Injectable({
  providedIn: 'root'
})
export class IconService {

  private iconsPath: string = '../../assets/leaflet';
  private _locationBlueIconFilename: string = 'last.png';
  private _locationRedIconFilename: string = 'last_location.png';
  private _greenFlagIconFilename: string = 'green-flag.png';
  private _redFlagIconFilename: string = 'red-flag.png';
  private locationIcon;
  private flagIcon;
  private reverseFlagIcon;
  private _locationBlueIcon;
  private _locationRedIcon;
  private _greenFlagIcon;
  private _redFlagIcon;

  constructor() {
    this.locationIcon = L.Icon.extend({
      options: {
        iconSize: [41, 41],
        iconAnchor: [21, 40]
      }
    });
    this.flagIcon = L.Icon.extend({
      options: {
        iconSize: [41, 41],
        iconAnchor: [3, 39]
      }
    });
    this.reverseFlagIcon = L.Icon.extend({
      options: {
        iconSize: [41, 41],
        iconAnchor: [38, 39]
      }
    });

    this._locationBlueIcon = new this.locationIcon({ iconUrl: `${this.iconsPath}/${this._locationBlueIconFilename}` });
    this._locationRedIcon = new this.locationIcon({ iconUrl: `${this.iconsPath}/${this._locationRedIconFilename}` });
    this._greenFlagIcon = new this.flagIcon({ iconUrl: `${this.iconsPath}/${this._greenFlagIconFilename}` });
    this._redFlagIcon = new this.reverseFlagIcon({ iconUrl: `${this.iconsPath}/${this._redFlagIconFilename}` });
  }

  public get locationBlueIcon() {
    return this._locationBlueIcon;
  }
  public get locationRedIcon() {
    return this._locationRedIcon;
  }
  public get greenFlagIcon() {
    return this._greenFlagIcon;
  }
  public get redFlagIcon() {
    return this._redFlagIcon;
  }


  public get locationBlueIconFilename() {
    return this._locationBlueIconFilename;
  }
  public get locationRedIconFilename() {
    return this._locationRedIconFilename;
  }
  public get greenFlagIconFilename() {
    return this._greenFlagIconFilename;
  }
  public get redFlagIconFilename() {
    return this._redFlagIconFilename;
  }


  setIcon(item: any, location: Location, icon: any, tooltip?: Function, clickAction?: Function, markerCluster?: MarkerClusterGroup): any {
    if (location.position?.coordinates) {
      const MARKER = new L.marker(location.position.coordinates, {
        icon: icon
      });
      if (clickAction) {
        MARKER.on('click', (event) => {
          clickAction(event, item);
        })
      }
      if (tooltip) MARKER.bindTooltip(tooltip(item)).openTooltip();
      if (markerCluster) { markerCluster.addLayer(MARKER); }
      return MARKER;
    }
  }


  setIcons(itemList: any[], locationList: Location[], icon: any, tooltip?: Function, clickAction?: Function, markerCluster?: MarkerClusterGroup, map?: any): void {
    if (itemList.length !== locationList.length) return;
    for (let i = 0; i < itemList.length; i++) {
      let marker = this.setIcon(itemList[i], locationList[i], icon, tooltip, clickAction, markerCluster);
      if (map && marker) marker.addTo(map);
    }
  }

}
