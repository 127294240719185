import { Component, OnInit, OnDestroy, ViewChild, HostListener } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { ListDetailComponent } from './list-detail/list-detail.component';
import { map, filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { ArrayList } from 'src/app/shared/utils/interface/ip-filter';
import { ListEntitiesService } from '../../../../services/apis/list-entities.service';
import { AssetList } from 'src/app/shared/utils/class/asset';
import { ListEntityEventsService } from '../../../../services/apis/list-entity-events.service';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { IpFilterComponent } from 'src/app/shared/components/ip-filter/ip-filter.component';
import { IpSearchComponent } from 'src/app/shared/components';
import { MapService } from '../../../../services/map.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ListService, TrackableEntityType } from '../../../../services/list.service';
import { TrackableEvent } from '../../../models/BiTAModel';
import { EventService } from 'src/app/services/event.service';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit, OnDestroy {
  @ViewChild('filter', { static: false }) filter: IpFilterComponent;
  @ViewChild('paginator', { static: false }) paginator: MatPaginator;
  @ViewChild('ipSearch', { static: false }) ipSearch: IpSearchComponent;

  pageSlice: any[];
  metadata;

  searchLength: number;

  arrayList: ArrayList[];
  selectedItem: string;
  trackableEntityApi: TrackableEntityType;
  assetList: AssetList;

  refresh: boolean = false;

  disableFilterSubscription: Subscription;
  searchSubscription: Subscription;
  radioChangeSubscription: Subscription;
  pageChangeSubscription: Subscription;
  refreshDataSubscription: Subscription;
  getListEntitySubscription: Subscription;

  showFilterResults: boolean = false;

  matLabelSearch: any;
  mobile: boolean = false;

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    console.log(event);
    const outerWidth = event.currentTarget.outerWidth;
    console.log(outerWidth);
    console.log(this.mobile);
    this.mobile = outerWidth < 720 ? true : false;
  }
  constructor(
    public dialog: MatDialog,
    private listEntitiesService: ListEntitiesService,
    private listEntityEventsService: ListEntityEventsService,
    private translate: TranslateService,
    private mapService: MapService,
    private route: ActivatedRoute,
    private router: Router,
    private listService: ListService,
    private eventService: EventService
  ) { }

  ngOnInit(): void {
    const loadLastSearch: boolean = this.route.snapshot.paramMap.get('loadLastSearch') === 'true' ? true : false;
    const id: string = this.route.snapshot.paramMap.get('ID');
    const defaultData: AssetList = {
      metadata: {
        filter: {},
        order: { entityType: 'ASC', ID: 'DESC' },
        pageSize: 20,
        pageNumber: 1
      }
    };
    const defaultSelectedItem: string = "Shipment";
    const defaultTrackableEntityApi: TrackableEntityType = 'shipments';

    this.arrayList = this.listService.getArrayList();

    if (loadLastSearch && this.listService.cacheData) {
      this.selectedItem = this.listService.cacheSelectedItem;
      this.trackableEntityApi = this.listService.cacheTrackableEntityApi;
      this.pageSlice = this.listService.cacheData.data;
      this.metadata = this.listService.cacheData.metadata;
      this.assetList = this.listService.cacheAssetList;
      this.openDetailIfSelected(id);
    } else {
      this.selectedItem = defaultSelectedItem;
      this.trackableEntityApi = defaultTrackableEntityApi;
      this.assetList = defaultData;
      this.getListEntitySubscription = this.getData();
    };

    this.matLabelSearch = this.listService.getSearchValue(this.selectedItem);
  }

  openDetailIfSelected(id: string) {
    if (!id) return;
    const selectedEntity: any = this.pageSlice.find(item => item.ID === id);
    if (selectedEntity) this.openDialog(selectedEntity);
  }

  openDialog(entityData?: any) {
    const filtro: AssetList = {
      metadata: { pageSize: 0, filter: { trackableEntityID: entityData.ID }, order: { performedTime: 'DESC' } }
    };

    // Si no existen lastTrackableEvent y no es un contenedor
    // no hay que mostrar modal con el listado de eventos
    if (!entityData.lastTrackableEvent && !entityData.transportUnitID) {
      this.noEventsMessage(entityData.ID);
      return;
    }

    // ANULA QUE NO SE PUEDE HACER CLICK N VECES
    // Y LANCE EL MODAL N VECES
    if (this.refresh) {
      return;
    }

    this.refresh = true;

    // TODO ORDER BY  updatedAt
    this.listEntityEventsService
      .getEntityEventList(filtro)
      .pipe(map(item => item.data))
      .subscribe(
        events => {
          if (events.length > 0) {
            const dialogRef = this.dialog.open(ListDetailComponent, {
              width: '90%',
              maxHeight: '90vh',
              maxWidth: '100vw',
              data: { listEvents: events, entityData, trackableEntityApi: this.trackableEntityApi, mobile: this.mobile }
            });
          } else {
            console.log(entityData);
            this.noEventsMessage(entityData.ID);
            return;
          }
        },
        err => err,
        () => {
          this.refresh = false;
        }
      );
  }

  noEventsMessage(entityData: string) {
    console.log('entity', entityData);
    return Swal.fire({
      position: 'center',
      icon: 'warning',
      text: this.translate.instant('trackable_list.not_event', { entityDataID: entityData }),
      showConfirmButton: true,
      confirmButtonColor: '#134696'
    });
  }

  showInMap() {
    console.log(this.pageSlice);
    const withLastTrackableEvent = this.pageSlice.filter(item => item.lastTrackableEvent);
    console.log(withLastTrackableEvent.length, withLastTrackableEvent);

    this.mapService.showInMapLocations = withLastTrackableEvent;

    const url = '/pages/map';
    this.router.navigate([url, 0, { showInMapButton: false }]);
  }

  metadataFilter(searchValue: string) {
    const selectedFilter = this.filter.selectedItem;
    let filtro;

    switch (true) {
      case selectedFilter === 'Train':
        filtro = {
          ...this.metadata.filter,
          searchIn: ['conveyorID', 'name', 'handlingUnits'],
          search: `/${searchValue}/`
        };
        return filtro;

      case selectedFilter === 'Vessel':
        filtro = {
          ...this.metadata.filter,
          searchIn: ['conveyorID', 'name', 'handlingUnits'],
          search: `/${searchValue}/`
        };
        return filtro;

      case selectedFilter === 'Truck':
        filtro = {
          ...this.metadata.filter,
          searchIn: ['name'],
          search: `/${searchValue}/`
        };
        return filtro;
      case selectedFilter === 'Shipment':
        filtro = { ...this.metadata.filter, searchIn: ['shipmentID', 'handlingUnits'], search: `/${searchValue}/` };
        return filtro;
      case selectedFilter === 'Container':
        filtro = { ...this.metadata.filter, searchIn: ['transportUnitID'], search: `/${searchValue}/` };
        return filtro;
      case selectedFilter === 'Stock':
        filtro = {
          ...this.metadata.filter,
          searchIn: ['stockID', 'handlingUnits', 'references.conveyor.identification.name'],
          search: `/${searchValue}/`
        };
        return filtro;
      default:
        filtro = { ...this.metadata.filter };
        return filtro;
    }
  }

  OnPageChange(event: PageEvent) {
    this.assetList.metadata.pageNumber = event.pageIndex + 1;
    this.assetList.metadata.pageSize = event.pageSize;

    this.pageChangeSubscription = this.getData();
  }

  search(event) {
    // RESETEAMOS EL PAGINATOR DE MATERIAL
    this.paginator.pageIndex = 0;

    this.assetList.metadata.pageNumber = 1;
    this.assetList.metadata.filter = null;

    if (event.length > 0) {
      this.assetList.metadata.filter = this.metadataFilter(event);
    }

    this.searchSubscription = this.getData();
  }

  radioChange(event) {
    // LIMPIAMOS EL INPUT CUANDO CAMBIEMOS EL FILTRO
    this.ipSearch.name = '';
    this.ipSearch.showClearButton = false;

    // RESETEAMOS EL PAGINATOR DE MATERIAL
    this.paginator.pageIndex = 0;

    this.assetList.metadata.pageNumber = 1;

    // CAMBIAMOS EL TEXTO A MOSTRAR EN EL BUSCADOR
    this.matLabelSearch = this.listService.getSearchValue(event);

    // MOSTRAR RESULTADOS FILTRO
    this.showFilterResults = true;

    switch (true) {
      case event === 'Container':
        this.assetList.metadata.filter = null;
        this.trackableEntityApi = 'handlingUnits';
        break;
      case event === 'Truck' || event === 'Train' || event === 'Vessel':
        this.assetList.metadata.filter = {
          conveyorType: event.toLowerCase()
        };
        this.trackableEntityApi = 'conveyors';
        break;
      case event === 'Shipment':
        this.assetList.metadata.filter = {
          shipmentID: `/./`
        };
        this.trackableEntityApi = 'shipments';
        break;
      case event === 'Stock':
        this.assetList.metadata.filter = null;
        this.trackableEntityApi = 'stockItems';
        break;
      default:
        this.assetList.metadata.filter = null;
    }

    this.refresh = true;

    this.radioChangeSubscription = this.getData();
  }

  disabledFilter() {

    // LIMPIAMOS EL INPUT CUANDO CAMBIEMOS EL FILTRO
    this.ipSearch.name = '';
    this.ipSearch.showClearButton = false;

    this.showFilterResults = false;

    this.assetList.metadata.pageNumber = 1;

    // Al quitar os filtros, volvemos a establecer shipment,
    // como filtro por defecto,Api por defecto y matLabelSearch de shipment
    this.trackableEntityApi = 'shipments';
    this.filter.selectedValue = 'filter.shipments';
    this.filter.selectedItem = 'Shipment';
    this.matLabelSearch = this.listService.getSearchValue(this.filter.selectedItem);

    this.assetList.metadata.filter = {
      shipmentID: `/./`
    };

    this.disableFilterSubscription = this.getData();
  }

  refreshData() {
    this.refreshDataSubscription = this.getData();
  }

  ngOnDestroy() {
    if (this.disableFilterSubscription) {
      this.disableFilterSubscription.unsubscribe();
    }

    if (this.getListEntitySubscription) {
      this.getListEntitySubscription.unsubscribe();
    }

    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }

    if (this.radioChangeSubscription) {
      this.radioChangeSubscription.unsubscribe();
    }

    if (this.pageChangeSubscription) {
      this.pageChangeSubscription.unsubscribe();
    }

    if (this.refreshDataSubscription) {
      this.refreshDataSubscription.unsubscribe();
    }
  }

  getIncidenciaStyle(event: TrackableEvent) {
    return this.eventService.isIncidencia(event) ? 'incidencia' : 'null';
  }

  private getData() {
    this.refresh = true;
    return this.listEntitiesService
      .getEntityListForEntity(this.assetList, this.trackableEntityApi)
      .subscribe(
        (trackableEntity: AssetList) => {
          this.pageSlice = trackableEntity.data;
          this.metadata = trackableEntity.metadata;
          this.updateCacheData(trackableEntity);
        },
        err => err,
        () => (this.refresh = false)
      );
  }

  private updateCacheData(asset: AssetList) {
    this.listService.cacheData = asset;
    this.listService.cacheSelectedItem = this.filter.selectedItem;
    this.listService.cacheTrackableEntityApi = this.trackableEntityApi;
    this.listService.cacheAssetList = this.assetList;
  }
}
