import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatInput } from '@angular/material/input';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

export interface ArrayList {
  value: string;
  translateKey: string;
  searchValue?: string[];
}

@Component({
  selector: 'ip-search',
  templateUrl: './ip-search.component.html',
  styleUrls: ['./ip-search.component.scss']
})
export class IpSearchComponent implements OnInit {
  @Output() search: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild('searchInput', { static: true }) public searchInput: MatInput;

  @Input() placeholder: string = '';
  @Input() matLabel: string = 'Description';
  @Input() class: string = 'col-md-6 mb-3';
  @Input() name: string = '';
  @Input() searchText: any[];
  @Input() isMatLabel: boolean = true;
  @Input() disabled: boolean = false;

  showClearButton: boolean = false;

  constructor() {}

  ngOnInit(): void {
    this.loadSearch();
    console.log('name', this.name);
  }

  clear() {
    this.name = '';
    this.showClearButton = false;
    this.search.emit('');
  }

  loadSearch() {
    const searchBox = document.getElementById('search');
    const keyup$ = fromEvent(searchBox, 'keyup');
    keyup$.pipe(debounceTime(500)).subscribe((event: any) => {
      this.showClearButton = event.target.value.length > 0 ? true : false;
      this.search.emit(event.target.value);
    });
  }
}
