import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
import { environment } from 'src/environments/environment';
import { MAP_LANGUAGES } from '../../utils/utils';

@Component({
  selector: 'ngx-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.scss']
})
export class LanguageComponent implements OnInit {
  @Input() buttonToggle: boolean = true;
  languages = MAP_LANGUAGES;
  language = 'es';
  base = environment.appUrl;

  constructor(private translate: TranslateService, private translateConfigService: TranslateConfigService) {
    if (localStorage.getItem('lang')) {
      this.language = localStorage.getItem('lang');
    }
    translate.setDefaultLang(this.language);
    this.translate.use(this.language);
    localStorage.setItem('lang', this.language);
  }

  ngOnInit() {}

  useLanguage(language: string) {
    this.language = language;
    localStorage.setItem('lang', language);
    this.translateConfigService.changeLanguage(language);
  }

  getMatDateLocale() {
    switch (this.language) {
      case 'es':
        return 'es-ES';
      case 'en':
        return 'en-EN';
      case 'it':
        return 'it-IT';
      case 'fr':
        return 'fr-FR';
      case 'de':
        return 'de-DE';
      default:
        return 'es-ES';
    }
  }
}
