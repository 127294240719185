<ip-loading></ip-loading>
<ip-loading [diameter]="100" [mode]="'determinate'" [color]="'warn'" [value]="'75'"></ip-loading>
<div class="container-fluid" [class.secondary-theme]="isTrue">
  <div class="mat-display-4">This is display 4</div>
  <div class="mat-display-3">This is display 3</div>
  <div class="mat-display-2">This is display 2</div>
  <div class="mat-display-1">This is display 1</div>

  <div class="mat-headline">This is a heading for h1</div>
  <div class="mat-title">This is a heading for h2</div>
  <div class="mat-subheading-2">This is a heading for h3</div>
  <div class="mat-subheading-1">This is a heading for h4</div>

  <button mat-raised-button type="button" class="mt-5" color="primary" (click)="changeTheme()">change</button>

  <p>dashboard works</p>
  <h1 translate>dashboard.title</h1>
  <h5 translate>dashboard.welcome_text</h5>
  <p>{{ 'dashboard.title' | translate | uppercase }}</p>

  <form class="example-form">
    <mat-form-field class="example-full-width">
      <mat-label>Favorite food</mat-label>
      <input matInput placeholder="Ex. Pizza" value="Sushi" />
    </mat-form-field>

    <mat-form-field class="example-full-width">
      <mat-label>Leave a comment</mat-label>
      <textarea matInput placeholder="Ex. It makes me feel..."></textarea>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Outline form field</mat-label>
      <input matInput placeholder="Placeholder" />
      <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
      <mat-hint>Hint</mat-hint>
    </mat-form-field>
  </form>

  <a routerLink="/settings" translate>settings.title</a>

  <p [innerHTML]="'dashboard.email_text' | translate: { email: emailResponse }"></p>

  <span [innerHTML]="'dashboard.sum_text' | translate: { value1: a, value2: b }"></span> {{ a + b }}
  <hr />
  <fa-icon [icon]="faCoffee"></fa-icon>

  <hr />

  <mat-form-field appearance="fill">
    <mat-label translate>dashboard.choose_date</mat-label>
    <input matInput [matDatepicker]="picker" />
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>

  <section>
    <div class="example-label text-primary">Basic</div>
    <div class="example-button-row">
      <button mat-button>Basic</button> <button mat-button color="primary">Primary</button>
      <button mat-button color="accent">Accent</button> <button mat-button color="warn">Warn</button>
      <button mat-button disabled>Disabled</button> <a mat-button href="https://www.google.com/" target="_blank">Link</a>
    </div>
  </section>
  <mat-divider></mat-divider>
  <section>
    <div class="example-label">Raised</div>
    <div class="example-button-row">
      <button mat-raised-button>Basic</button> <button mat-raised-button color="primary">Primary</button>
      <button mat-raised-button color="accent">Accent</button> <button mat-raised-button color="warn">Warn</button>
      <button mat-raised-button disabled>Disabled</button>
      <a mat-raised-button href="https://www.google.com/" target="_blank">Link</a>
    </div>
  </section>
  <mat-divider></mat-divider>
  <section>
    <div class="example-label">Stroked</div>
    <div class="example-button-row">
      <button mat-stroked-button>Basic</button> <button mat-stroked-button color="primary">Primary</button>
      <button mat-stroked-button color="accent">Accent</button> <button mat-stroked-button color="warn">Warn</button>
      <button mat-stroked-button disabled>Disabled</button>
      <a mat-stroked-button href="https://www.google.com/" target="_blank">Link</a>
    </div>
  </section>
  <mat-divider></mat-divider>
  <section>
    <div class="example-label">Flat</div>
    <div class="example-button-row">
      <button mat-flat-button>Basic</button> <button mat-flat-button color="primary">Primary</button>
      <button mat-flat-button color="accent">Accent</button> <button mat-flat-button color="warn">Warn</button>
      <button mat-flat-button disabled>Disabled</button>
      <a mat-flat-button href="https://www.google.com/" target="_blank">Link</a>
    </div>
  </section>
  <mat-divider></mat-divider>
  <section>
    <div class="example-label">Icon</div>
    <div class="example-button-row">
      <div class="example-flex-container">
        <button mat-icon-button aria-label="Example icon button with a vertical three dot icon">
          <mat-icon>more_vert</mat-icon>
        </button>
        <button mat-icon-button color="primary" aria-label="Example icon button with a home icon">
          <mat-icon>home</mat-icon>
        </button>
        <button mat-icon-button color="accent" aria-label="Example icon button with a menu icon">
          <mat-icon>menu</mat-icon>
        </button>
        <button mat-icon-button color="warn" aria-label="Example icon button with a heart icon">
          <mat-icon>favorite</mat-icon>
        </button>
        <button mat-icon-button disabled aria-label="Example icon button with a open in new tab icon">
          <mat-icon>open_in_new</mat-icon>
        </button>
      </div>
    </div>
  </section>
  <mat-divider></mat-divider>
  <section>
    <div class="example-label">FAB</div>
    <div class="example-button-row">
      <div class="example-flex-container">
        <div class="example-button-container">
          <button mat-fab color="primary" aria-label="Example icon button with a delete icon">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
        <div class="example-button-container">
          <button mat-fab color="accent" aria-label="Example icon button with a bookmark icon">
            <mat-icon>bookmark</mat-icon>
          </button>
        </div>
        <div class="example-button-container">
          <button mat-fab color="warn" aria-label="Example icon button with a home icon"><mat-icon>home</mat-icon></button>
        </div>
        <div class="example-button-container">
          <button mat-fab disabled aria-label="Example icon button with a heart icon"><mat-icon>favorite</mat-icon></button>
        </div>
      </div>
    </div>
  </section>
  <mat-divider></mat-divider>
  <section>
    <div class="example-label">Mini FAB</div>
    <div class="example-button-row">
      <div class="example-flex-container">
        <div class="example-button-container">
          <button mat-mini-fab color="primary" aria-label="Example icon button with a menu icon">
            <mat-icon>menu</mat-icon>
          </button>
        </div>
        <div class="example-button-container">
          <button mat-mini-fab color="accent" aria-label="Example icon button with a plus one icon">
            <mat-icon>plus_one</mat-icon>
          </button>
        </div>
        <div class="example-button-container">
          <button mat-mini-fab color="warn" aria-label="Example icon button with a filter list icon">
            <mat-icon>filter_list</mat-icon>
          </button>
        </div>
        <div class="example-button-container">
          <button mat-mini-fab disabled aria-label="Example icon button with a home icon"><mat-icon>home</mat-icon></button>
        </div>
      </div>
    </div>
  </section>

  <div class="row">
    <div class="col-3">
      <button mat-raised-button type="button" class="mt-5" color="primary" (click)="changeAdapter()">LOCALE ES</button>
    </div>
    <div class="col-3">
      <button mat-raised-button type="button" class="mt-5" color="primary" (click)="french()">LOCALE FR</button>
    </div>
    <div class="col-3">
      <button mat-raised-button type="button" class="mt-5" color="primary" (click)="england()">LOCALE EN</button>
    </div>
    <div class="col-3">
      <mat-form-field appearance="fill">
        <input matInput [matDatepicker]="dp" />
        <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
        <mat-datepicker #dp></mat-datepicker>
      </mat-form-field>
    </div>
  </div>

  <mat-slider min="1" max="100" step="1" value="1"></mat-slider>

  <ip-toolbar
    title="{{ 'home.privacy_cookies' | translate | uppercase }}"
    color="primary"
    arrowColor="text-primary"
    textColor="text-danger"
  ></ip-toolbar>
  <ip-toolbar
    title="{{ 'home.privacy_cookies' | translate | uppercase }}"
    color="accent"
    arrowColor="text-success"
    textColor="text-info"
  ></ip-toolbar>
  <ip-toolbar
    title="{{ 'home.privacy_cookies' | translate | uppercase }}"
    color="warn"
    arrowColor="text-danger"
    textColor="text-light"
  ></ip-toolbar>

  <ip-toolbar
    title="{{ 'home.privacy_cookies' | translate | uppercase }}"
    color="secondary"
    arrowColor="text-danger"
    textColor="text-info"
  ></ip-toolbar>

  <ip-toolbar
    title="{{ 'home.privacy_cookies' | translate | uppercase }}"
    color="primary"
    arrowColor="text-danger"
    textColor="text-info"
  ></ip-toolbar>

  <ip-toolbar
    title="{{ 'home.privacy_cookies' | translate | uppercase }}"
    arrowColor="text-danger"
    textColor="text-info"
  ></ip-toolbar>
</div>
