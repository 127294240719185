export const ROLES = {
  accessControl: {
    guest: {
      viewMenu: ['Home', 'Auth'],
      read: ['Home']
    },
    usrmanager: {
      save: ['User']
    },
    admin: {
      viewMenu: ['Dashboard', 'Auth', 'Locations', 'User'],
      read: ['Dashboard', 'Auth', 'Locations', 'User'],
      save: ['Dashboard', 'Auth', 'Locations', 'User'],
      saveAny: ['Dashboard', 'Auth', 'Locations', 'User']
    }
  }
};

// DOCUMENTACION NEBULAR

// export const ROLES = {
//   accessControl: {
//     guest: {
//       viewMenu: ['Home', 'Auth'],
//     },
//     user: {
//       parent: 'guest',
//       create: 'Home',
//     },
//     moderator: {
//       parent: 'user',
//       create: 'Auth',
//       remove: '*',
//     }
//   }
// };
