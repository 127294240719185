<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle"> <nb-icon icon="menu-2-outline"></nb-icon> </a>
    <a class="logo" href="#" (click)="navigateHome()">
      <img src="../../../../assets/images/b2t2.png" /> <span class="d-none d-sm-inline"> {{ 'header.title' | translate }}</span>
    </a>
  </div>
</div>
<div class="header-container">
  <nb-actions size="small">
    <!-- <nb-action icon="color-palette" (click)="changeTheme()" *nbIsGranted="['read', 'User']"></nb-action> -->
    <nb-action *ngIf="!usuario?.email" icon="lock-outline" title="Login" (click)="login()"> </nb-action>
    <nb-action class="user-action" *nbIsGranted="['read', 'User']">
      <nb-user
        [nbContextMenu]="userMenu"
        [onlyPicture]="userPictureOnly"
        [name]="usuario?.fullName"
        [picture]="usuario?.picture"
      >
      </nb-user>
    </nb-action>
    <nb-action> <ngx-language [buttonToggle]="false"></ngx-language> </nb-action>
  </nb-actions>
</div>
