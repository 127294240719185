import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'split'
})
export class SplitPipe implements PipeTransform {
  transform(value: string, value2?: string): any {
    const split: any = value2.split('@', 1);
    return `${value}@${split[0]}`;
  }
}
