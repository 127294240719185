class ListMetadata {
  asset?: string = '';
  filter?: any;
  filterCommand?: any;
  order?: any;
  select?: string = '';
  count?: number = 0;
  pageSize?: number = 10;
  pageNumber?: number = 1;
  start?: number = 0;
  firstPage?: boolean = false;
  lastPage?: boolean = false;
  lang?: string = 'es';
  company?: string;
}

export class AssetList {
  metadata: ListMetadata = new ListMetadata();
  data?: any;
}

class AssetMetadata {
  operation?: string;
  asset?: string;
  id?: string;
  lang?: string = 'es';
}

export class Asset {
  metadata: AssetMetadata = new AssetMetadata();
  data?: any;
}

export class AssetDefinition {
  selectParams: string[];
  filterParams: string[];
  orderByParams: string[];
}
