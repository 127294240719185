import { Component, OnInit } from '@angular/core';
import { NbAclService } from '@nebular/security';
import { NbMenuItem } from '@nebular/theme';
import { TranslateService } from '@ngx-translate/core';
import { distinctUntilChanged } from 'rxjs/operators';
import { AuthGuardService } from '../auth/services/auth-guard.service';
import { MenuItems } from '../shared/utils/pages-menu';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss']
})
export class PagesComponent implements OnInit {
  title = 'B2T2 Track & Trace';
  public menu = [];

  constructor(private translate: TranslateService, private authGuard: AuthGuardService, private nbAclService: NbAclService) {
    const menuItems = new MenuItems(this.translate);
    this.menu = menuItems.MENU_ITEMS;
  }
  ngOnInit() {
    // Si existe en el localStorage, coge el idioma de localStorage, si no el del navegador
    this.translate.use(localStorage.getItem('lang') ? localStorage.getItem('lang') : this.translate.getBrowserLang());

    this.authGuard
      .onUserChange()
      .pipe(distinctUntilChanged())
      .subscribe(user => {
        const authItems = this.menu.find(item => item.data === 'Auth');
        authItems.children.find(item => item.data === 'Login').hidden = user.roles.length > 0;
        authItems.children.find(item => item.data === 'RequestPassword').hidden = user.roles.length > 0;
        authItems.children.find(item => item.data === 'ResetPassword').hidden = !(user.roles.length > 0);
        authItems.children.find(item => item.data === 'Logout').hidden = !(user.roles.length > 0);
        // authItems.children.find(item => item.data === 'Register').hidden = user.roles.length > 0;
        for (const item of this.menu) {
          this.checkGranted(user.roles, item);
        }
      });
  }

  checkGranted(roles: string[], item: NbMenuItem): void {
    if (item.data) {
      let hidden = true;
      if (roles.length === 0) {
        roles = ['guest'];
      }
      roles.forEach(role => (this.nbAclService.can(role, 'viewMenu', item.data) ? (hidden = false) : hidden));
      item.hidden = hidden;
    }
  }
}
