import { Injectable } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class TranslateConfigService {
  supportedLanguages = ['es', 'it', 'en'];
  defaultLanguage = 'es';
  userLanguage = localStorage.getItem('lang') || this.translate.getBrowserLang();

  constructor(private translate: TranslateService) {
    this.translate.use(this.userLanguage);
  }

  changeLanguage(language: string) {
    this.translate.use(language);
    window.location.reload();
  }

  getMatDateLocale() {
    switch (this.userLanguage) {
      case 'es':
        return 'es-ES';
      case 'en':
        return 'en-EN';
      case 'it':
        return 'it-IT';
      case 'fr':
        return 'fr-FR';
      case 'de':
        return 'de-DE';
      default:
        return 'en-EN';
    }
  }

  getLanguage() {
    return this.supportedLanguages.includes(this.userLanguage) ? this.userLanguage : this.defaultLanguage;
  }
}
