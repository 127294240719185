<!-- windowMode -->
<nb-layout>
  <nb-layout-header fixed> <ip-header></ip-header> </nb-layout-header>

  <nb-sidebar class="menu-sidebar" tag="menu-sidebar" responsive> <ng-content select="nb-menu"></ng-content> </nb-sidebar>

  <nb-layout-column> <ng-content select="router-outlet"></ng-content> </nb-layout-column>

  <nb-layout-footer fixed> <ip-footer></ip-footer> </nb-layout-footer>
</nb-layout>
