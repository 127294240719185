import { HostListener, Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MapService } from '../../../../../services/map.service';

@Component({
  selector: 'app-list-detail',
  templateUrl: './list-detail.component.html',
  styleUrls: ['./list-detail.component.scss']
})
export class ListDetailComponent implements OnInit {
  alternate: boolean = true;
  toggle: boolean = true;
  color: boolean = false;
  size: number = 30;
  expandEnabled: boolean = false;
  contentAnimation: boolean = false;
  dotAnimation: boolean = false;
  side = 'left';

  constructor(
    public dialogRef: MatDialogRef<ListDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private mapService: MapService
  ) { }

  ngOnInit(): void {
    console.log('data', this.data);
  }

  showInMap(): void {
    this.dialogRef.close();
    const destinations = this.data.entityData.destinations
      ? this.data.entityData.destinations
      : this.data.entityData.destination;
    const origins = this.data.entityData.origins ? this.data.entityData.origins : this.data.entityData.origin;
    const lastPosition = this.data.listEvents[0];
    const trackableEntityApi = this.data.trackableEntityApi;
    const ID: string = this.data.entityData.ID;

    // origins.forEach((element, index) => {
    //   origins[index].etc = `201${index + 1}-01-03T07:30:00.000Z`;
    // });

    // const SORT = origins.filter(item => item.eta).sort(this.sortByETA);

    // console.log('SORT', SORT);
    const showInMap = {
      origins,
      destinations,
      lastPosition,
      trackableEntityApi,
      ID
      // lastPosition: SORT.length > 0 ? SORT[0] : null
    };
    console.log('showInMap', showInMap);

    this.mapService.showInMap = showInMap;

    const url = '/pages/map';
    this.router.navigate([url, this.data.entityData._id, { showInMapButton: true }]);
  }

  onHeaderClick(event) {
    if (!this.expandEnabled) {
      event.stopPropagation();
    }
  }

  onDotClick(event) {
    if (!this.expandEnabled) {
      event.stopPropagation();
    }
  }

  sortByETA(a, b) {
    // ORDER DECRECIENTE
    if (a.eta < b.eta) {
      return 1;
    }
    if (a.eta > b.eta) {
      return -1;
    }
    return 0;
  }
}
