import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ip-footer',
  templateUrl: './ip-footer.component.html',
  styleUrls: ['./ip-footer.component.scss']
})
export class IpFooterComponent implements OnInit {
  year = new Date().getFullYear();
  constructor() {}

  ngOnInit(): void {}
}
