import { HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';
import { Asset, AssetList } from '../../shared/utils/class/asset';

export class AbstractRestApi {
  constructor(protected http: HttpClient) {}

  protected httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept-Language': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'es'
    })
  };

  protected generateQuery(assetList: AssetList): string {
    let query: string = '?';
    if (assetList.metadata.lang) {
      query += `language=${assetList.metadata.lang}&`;
    }
    if (assetList.metadata.pageNumber) {
      query += `pageNumber=${assetList.metadata.pageNumber}&`;
    }
    if (assetList.metadata.pageSize) {
      query += `pageSize=${assetList.metadata.pageSize}&`;
    }
    if (assetList.metadata.select) {
      query += `select=${assetList.metadata.select}&`;
    }
    if (assetList.metadata.filter) {
      Object.keys(assetList.metadata.filter).forEach((key: string) => {
        query += `${key}=${assetList.metadata.filter[key]}&`;
      });
    }
    if (assetList.metadata.order) {
      Object.keys(assetList.metadata.order).forEach((key: string) => {
        query += `orderBy-${key}=${assetList.metadata.order[key]}&`;
      });
    }
    assetList.metadata.lang = localStorage.getItem('lang');
    return query;
  }

  protected generateAsset(data: any) {
    const asset = new Asset();
    asset.metadata = {
      lang: localStorage.getItem('lang')
    };
    asset.data = data;
    return asset;
  }

  // Error handling
  protected handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    error.message = errorMessage;
    throwError(null);
  }
}
