<mat-form-field appearance="outline" class="{{ class }}">
  <mat-label *ngIf="isMatLabel">{{ matLabel }}</mat-label>

  <mat-label>
    {{ 'search.searchBy' | translate }}:
    <span *ngFor="let item of searchText; let i = index">
      {{ item | translate }}<span *ngIf="i < searchText.length - 1">,</span>
    </span>
  </mat-label>

  <input
    matInput
    placeholder="{{ placeholder | translate | titlecase }}"
    id="search"
    name="{{ name }}"
    #searchInput
    [(ngModel)]="name"
    [disabled]="disabled"
  />

  <mat-icon matSuffix>search</mat-icon>
  <mat-icon *ngIf="showClearButton" matSuffix class="clear" (click)="clear()">clear</mat-icon>
</mat-form-field>
