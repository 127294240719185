<ip-toolbar
  title="{{ 'home.term_of_use' | translate | uppercase }}"
  color="secondary"
  arrowColor="text-secondary"
  textColor="text-secondary"
></ip-toolbar>
<div class="container-fluid mt-2">
  <div class="container-fluid mt-2">
    <p>{{ 'terms_use.terms_use_p1' | translate }}</p>
    <p>{{ 'terms_use.terms_use_p2' | translate }}</p>
    <p>{{ 'terms_use.terms_use_p3' | translate }}</p>
    <p>{{ 'terms_use.terms_use_p4' | translate }}</p>
    <p>{{ 'terms_use.terms_use_p5' | translate }}</p>
    <p>{{ 'terms_use.terms_use_p6' | translate }}</p>
    <p>{{ 'terms_use.terms_use_p7' | translate }}</p>
    <p>{{ 'terms_use.terms_use_p8' | translate }}</p>
    <p>{{ 'terms_use.terms_use_p9' | translate }}</p>
    <p>{{ 'terms_use.terms_use_p10' | translate }}</p>
    <p>{{ 'terms_use.terms_use_p11' | translate }}</p>
    <p>{{ 'terms_use.terms_use_p12' | translate }}</p>
    <p>{{ 'terms_use.terms_use_p13' | translate }}</p>
    <p>{{ 'terms_use.terms_use_p14' | translate }}</p>
  </div>
</div>
