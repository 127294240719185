import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MapService {
  showInMap: any;
  showInMapLocations: any[];

  constructor() { }

  createLegend(L: any, legendContent: any[]): any {
    const Legend = L.control({ position: 'bottomright' });
    Legend.onAdd = function (map) {
      // tslint:disable-next-line: one-variable-per-declaration
      const div = L.DomUtil.create('div', 'info_legend'),
        legend = legendContent;

      // loop through our density intervals and generate a label with a colored square for each interval
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < legend.length; i++) {
        div.innerHTML += `
          <img style="width: 30px;height:auto;margin-bottom:5px;" class="center" src="../../../../assets/leaflet/${legend[i].image
          }" alt="" />
          ${legend[i].language}   <br>
       `;
      }
      return div;
    };
    return Legend;
  }
}
