declare const L: any;
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { MapService } from '../../../../services/map.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MarkerClusterGroup } from 'leaflet.markercluster';
import { Location, TrackableEvent } from '../../../models/BiTAModel';
import { EventService } from 'src/app/services/event.service';
import * as moment from 'moment';
import { IconService } from '../../../../services/icon.service';

import { MatMenuTrigger } from '@angular/material/menu';
import { MatDialog } from '@angular/material/dialog';

import { ListDetailComponent } from '../list/list-detail/list-detail.component';
import { AssetList } from 'src/app/shared/utils/class/asset';
import { ListEntityEventsService } from 'src/app/services/apis/list-entity-events.service';
import { map } from 'rxjs/operators';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit, OnDestroy {
  // private token = 'pk.eyJ1IjoiaGJ1ZW5vIiwiYSI6ImNrcHAzcm5qYTM1NjMyd3JpYzFoNng3OXAifQ.GvgksgUfNqDbqWT-T2Z7LQ';
  showInMapButton: boolean;

  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  menuX: number = 0
  menuY: number = 0
  opened: boolean;

  private selectedItem: any = {};


  constructor(
    private mapService: MapService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private router: Router,
    private eventService: EventService,
    private iconService: IconService,
    private listEntityEventsService: ListEntityEventsService,
    public dialog: MatDialog
  ) { }

  ngOnDestroy() {
    // localStorage.removeItem('showInMap');
  }

  ngOnInit(): void {
    // GUARDAMOS EN EL LOCALSTORAGE POR SI SE RECARGA LA PAGINA
    // NO PERDER EL DATO
    if (this.mapService.showInMap) {
      localStorage.setItem('showInMap', JSON.stringify(this.mapService.showInMap));
    }

    if (this.mapService.showInMapLocations) {
      localStorage.setItem('showInMapLocations', JSON.stringify(this.mapService.showInMapLocations));
    }

    if (!navigator.geolocation) {
      window.alert('location is not supported'); // TODO: Añadir coordenadas por defecto
    }

    navigator.geolocation.getCurrentPosition(position => {
      const coords = position.coords;
      const latLong = [coords.latitude, coords.longitude];
      const mymap = L.map('map').setView(latLong, 6);

      // LAYERS
      // OPEN STREET MAP
      L.tileLayer('https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png', {
        attribution:
          '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
        subdomains: 'abcd',
        maxZoom: 19
      }).addTo(mymap);
      // END OPEN STREET MAP
      // END LAYERS

      const showInMapButton: boolean = this.route.snapshot.paramMap.get('showInMapButton') === 'true' ? true : false;
      // mymap.on('click', this.onMapClick);

      this.showInMapButton = showInMapButton;

      this.addLegend(L, mymap, showInMapButton);

      if (showInMapButton) {
        const localStorageShowInMap = JSON.parse(localStorage.getItem('showInMap'));

        const origins = localStorageShowInMap.origins;
        const destinations = localStorageShowInMap.destinations;
        const lastPosition = localStorageShowInMap.lastPosition;

        if (localStorageShowInMap.trackableEntityApi !== 'conveyors') {
          // ORIGENES
          this.iconService.setIcons(origins, origins, this.iconService.greenFlagIcon, this.tooltipEntitiyLocation('origin'), null, null, mymap);
          // END ORIGENES

          // DESTINOS
          this.iconService.setIcons(destinations, destinations, this.iconService.redFlagIcon, this.tooltipEntitiyLocation('destination'), null, null, mymap);
          // END DESTINOS

        } else if (localStorageShowInMap.trackableEntityApi === 'conveyors') {
          // ORIGEN

          if (origins && origins.position && origins.position.coordinates) {
            this.iconService.setIcon(origins, origins, this.iconService.greenFlagIcon).addTo(mymap);
          }

          if (destinations && destinations.position && destinations.position.coordinates) {
            // DESTINO
            this.iconService.setIcon(destinations, destinations, this.iconService.redFlagIcon).addTo(mymap);
          }

          // END DESTINO
        }

        // LAST POSITION

        if (lastPosition.location && lastPosition.location.position && lastPosition.location.position.coordinates) {
          return this.iconService.setIcon(lastPosition, lastPosition.location, this.iconService.locationRedIcon, this.tooltipEventLocation).addTo(mymap);
        }


      } else if (!showInMapButton) {
        const localStorageshowInMapLocations = JSON.parse(localStorage.getItem('showInMapLocations'));

        const lasTrackableEvent = localStorageshowInMapLocations;

        let locations: any[] = lasTrackableEvent.filter(
          item =>
            item.lastTrackableEvent.location &&
            item.lastTrackableEvent.location.position &&
            item.lastTrackableEvent.location.position.coordinates
        );

        const markerCluster = new MarkerClusterGroup();

        let incidencias = locations.filter(location => this.eventService.isIncidencia(location.lastTrackableEvent));
        let posiciones = locations.filter(location => !this.eventService.isIncidencia(location.lastTrackableEvent));

        this.setIconsLast(incidencias, this.iconService.locationRedIcon, markerCluster);
        this.setIconsLast(posiciones, this.iconService.locationBlueIcon, markerCluster);

        mymap.addLayer(markerCluster);

        // MARKER.on('mouseover', () => MARKER.bindPopup(`<h4>HB MOUSE OVER</h4><br> Coordenadas: ${latLong}`).openPopup());
        // MARKER.on('mouseout', () => MARKER.closePopup());
        // MARKER.on('popupopen', () => console.log('open popup'));
        // MARKER.on('popupclose', () => console.log('close popup'));
      }
    });
  }

  showList() {
    const url = '/pages/list';
    this.router.navigate([url, { loadLastSearch: true }]);
  }

  showDetail() {
    const localStorageShowInMap = JSON.parse(localStorage.getItem('showInMap'));
    let parameters: any = { loadLastSearch: true };

    if (localStorageShowInMap.ID) parameters.ID = localStorageShowInMap.ID;

    const url = '/pages/list';
    this.router.navigate([url, parameters]);
  }

  private addLegend(L: any, mymap: any, showInMapButton: boolean) {
    let legendContent: any[] = [];

    if (showInMapButton) {
      const origins: string = this.translate.instant('map.origin_coordinates');
      const destinations: string = this.translate.instant('map.destination_coordinates');
      const lastPosition: string = this.translate.instant('map.last_position');

      legendContent = [
        { language: origins, image: this.iconService.greenFlagIconFilename },
        { language: destinations, image: this.iconService.redFlagIconFilename },
        { language: lastPosition, image: this.iconService.locationRedIconFilename }
      ];
    } else {
      const incidences: string = this.translate.instant('map.last_position_incident');
      const lastPosition: string = this.translate.instant('map.last_position');

      legendContent = [
        { language: lastPosition, image: this.iconService.locationBlueIconFilename },
        { language: incidences, image: this.iconService.locationRedIconFilename }
      ];
    }

    const Legend = this.mapService.createLegend(L, legendContent);

    Legend.addTo(mymap);
  }

  // onMapClick(e) {
  //   alert('You clicked the map at ' + e.latlng);
  // }

  detailsAction() {
    console.log('details...');
    this.openDialog(this.selectedItem);
  }

  private setIconsLast(list: any[], icon: any, markerCluster?: MarkerClusterGroup): void {
    this.iconService.setIcons(list, list.map(item => item.lastTrackableEvent.location), icon, this.tooltipContent, this.mapMenu, markerCluster);
  }

  private setIconEvent(item: any, icon: any): any {
    return this.iconService.setIcon(item, item.location, icon)
  }

  private mapMenu = (event: any, item: any) => {
    this.menuX = event.originalEvent.clientX;
    this.menuY = event.originalEvent.clientY;

    this.selectedItem = item;

    this.trigger.openMenu();
  }


  openDialog(entityData?: any) {
    const filtro: AssetList = {
      metadata: { pageSize: 0, filter: { trackableEntityID: entityData.ID }, order: { performedTime: 'DESC' } }
    };
    const showInMapButton: boolean = this.route.snapshot.paramMap.get('showInMapButton') === 'true' ? true : false;


    const localStorageShowInMap = showInMapButton ? JSON.parse(localStorage.getItem('showInMap')) : JSON.parse(localStorage.getItem('showInMapLocations'));

    // Si no existen lastTrackableEvent y no es un contenedor
    // no hay que mostrar modal con el listado de eventos
    if (!entityData.lastTrackableEvent && !entityData.transportUnitID) {
      this.noEventsMessage(entityData.ID);
      return;
    }

    // TODO ORDER BY  updatedAt
    this.listEntityEventsService
      .getEntityEventList(filtro)
      .pipe(map(item => item.data))
      .subscribe(
        events => {
          if (events.length > 0) {
            const dialogRef = this.dialog.open(ListDetailComponent, {
              width: '90%',
              maxHeight: '90vh',
              maxWidth: '100vw',
              data: { listEvents: events, entityData, trackableEntityApi: localStorageShowInMap.trackableEntityApi, mobile: true }
            });
          } else {
            console.log(entityData);
            this.noEventsMessage(entityData.ID);

            return;
          }
        },
        err => err
      );
  }



  private tooltipContent = (item: any) => {
    const title: string = this.getTitle(item);
    const origin: string = this.getOrigin(item);
    const destination: string = this.getDestination(item);
    const performedTime: string = this.getPerformedTime(item);
    const eta: string = this.getEta(item);
    const state: string = this.getStateEntity(item);

    const list = [title, performedTime, origin, destination, eta, state];

    return this.getContainer(list);
  }

  private tooltipEntitiyLocation(typeLoc: string) {
    return (item: Location) => {
      const title: string = this.getTitleRow(this.translate.instant(`map.${typeLoc}`));
      const position: string = this.getLocationData(item, typeLoc);

      const list = [title, position];
      return this.getContainer(list);
    }
  }

  private tooltipEventLocation = (item: TrackableEvent) => {
    const title: string = this.getTitleRow(this.translate.instant('map.last_position'));
    const position: string = this.getLocationData(item.location, 'event');
    const conveyor: string = this.getConveyorData(item);
    const state: string = this.getStateEvent(item);


    const list = [title, position, conveyor, state];
    return this.getContainer(list);
  }

  private getOrigin(item: any) {
    return this.getOriginDestination(item, 'origin', 'origins');
  }

  private getDestination(item: any) {
    return this.getOriginDestination(item, 'destination', 'destinations');
  }

  private getOriginDestination(item: any, uniqueKey: string, listKey: string): string {
    let label: string;
    let content: string;
    if (item[listKey]) {
      label = this.translate.instant(`map.${listKey}`);
      if (!item[listKey].length) {
        content = this.translate.instant('map.unknown');
      } else {
        content = '';
        item[listKey].forEach(dest => {
          content += this.getLocationData(dest, uniqueKey);
        });
      }
    } else if (item[uniqueKey]) {
      label = this.translate.instant(`map.${uniqueKey}`);
      content = item[uniqueKey]?.locationName ? item[uniqueKey].locationName : this.translate.instant('map.unknown');
    }
    return this.getTooltipRow(label, content);
  }

  private getLocationData(loc: Location, typeLoc: string) {
    let content = '';
    if (loc.locationName) content += `<div style="font-size: 1em;">${loc.locationName}</div>`;
    if ((typeLoc === 'origin' || typeLoc === 'event') && loc.etd) content += this.getDateRow(loc.etd, 'ETD');
    if ((typeLoc === 'origin' || typeLoc === 'event') && loc.atd) content += this.getDateRow(loc.etd, 'ATD');
    if ((typeLoc === 'destination' || typeLoc === 'event') && loc.eta) content += this.getDateRow(loc.etd, 'ETA');
    if ((typeLoc === 'destination' || typeLoc === 'event') && loc.ata) content += this.getDateRow(loc.etd, 'ATA');
    return content;
  }

  private getStateEntity(item: any): string {
    if (item.lastTrackableEvent) { return this.getStateEvent(item.lastTrackableEvent) }
    else { return '' }
  }

  private getStateEvent(event: TrackableEvent): string {
    let color = this.eventService.isIncidencia(event) ? 'red' : 'blue';
    return `<div style="text-align:center;color:${color};">${event.eventType}</div>`;
  }

  private getConveyorData(event: TrackableEvent): string {
    let label: string = this.getConveyorIcon(event.conveyor?.type);
    let content: string = event.conveyor?.identification?.name ? event.conveyor.identification.name : '';

    return (label || content) ? this.getTooltipRow(label, content) : '';
  }

  private getPerformedTime(item: any): string {
    let label: string = this.translate.instant('map.date');
    let content: string = item.lastTrackableEvent?.performedTime ? moment(item.lastTrackableEvent.performedTime).format('DD/MM/YYYY HH:mm') : this.translate.instant('map.unknown');
    return this.getTooltipRow(label, content);
  }

  private getEta(item: any): string {
    let eta: string = '';
    if (item.destination) {
      let label: string = this.translate.instant('map.eta');
      let content: string = item.destination?.eta ? moment(item.destination.eta).format('DD/MM/YYYY HH:mm') : this.translate.instant('map.unknown');
      eta = this.getTooltipRow(label, content);
    }
    return eta;
  }

  private getConveyorIcon(conveyorType: string): string {
    let icon: string;
    switch (conveyorType) {
      case 'truck':
        icon = '<span class="material-icons">local_shipping</span>';
        break;
      case 'vessel':
        icon = '<span class="material-icons">directions_boat</span>';
        break;
      case 'train':
        icon = '<span class="material-icons">train</span>';
        break;
      default:
        icon = '';
        break;
    }
    return icon;
  }

  private getTooltipRow(label: string, content: string): string {
    return `<div style="display:flex;margin-bottom: 5px;"><div style="display:inline-block; width: 30%;">${label}:</div><div style="display: inline-block; width: 70%">${content}</div></div>`
  }

  private getTitleRow(title: string): string {
    return `<h4 style="text-align:center;">${title}</h4>`;
  }

  private getDateRow(date: any, label: string): string {
    return `<div style="font-size: 0.9em;margin-bottom: 2px;padding-left: 0.5em;">${label}: ${moment(date).format('DD/MM/YYYY HH:mm')}</div>`;
  }

  private getContainer(list: string[]): string {
    let res: string = '<div style="width:230px">';
    list.forEach(item => res += item);
    res += '</div>';
    return res;
  }

  private getTitle(item: any): string {
    let title: string;
    switch (item.assetType) {
      case "conveyor":
        title = item.conveyorID;
        break;
      case "shipment":
        title = item.shipmentID;
        break;
      case "transportUnit":
        title = item.transportUnitID;
        break;
      case "stockItem":
        title = item.stockID;
        break;
    }
    return this.getTitleRow(title);
  }

  private noEventsMessage(entityData: string) {
    console.log('entity', entityData);
    return Swal.fire({
      position: 'center',
      icon: 'warning',
      text: this.translate.instant('trackable_list.not_event', { entityDataID: entityData }),
      showConfirmButton: true,
      confirmButtonColor: '#134696'
    });
  }
}
