import { Injectable } from '@angular/core';
import { TrackableEvent } from '../pages/models/BiTAModel';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  constructor() { }

  isIncidencia(event: TrackableEvent) {
    return event &&
      (event.eventType === 'delayed' ||
        event.eventType === 'stopped' ||
        event.eventType === 'detained' ||
        event.eventType === 'cancelled')
  }
}
