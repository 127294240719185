<button mat-raised-button class="refresh map-button" (click)="showList()" *ngIf="!showInMapButton">
  <span class="material-icons-outlined"> featured_play_list </span> {{ 'map.show_list' | translate }}
</button>

<button mat-raised-button class="refresh map-button" (click)="showDetail()" *ngIf="showInMapButton">
  <span class="material-icons-outlined"> featured_play_list </span> {{ 'map.show_detail' | translate }}
</button>

<span [matMenuTriggerFor]="menu" [style.position]="'absolute'" [style.left.px]="menuX" [style.top.px]="menuY"></span>
<mat-menu #menu="matMenu" [overlapTrigger]="true">
  <button mat-menu-item (click)="detailsAction()">{{ 'map.details' | translate }}</button>
</mat-menu>

<div id="map"></div>
