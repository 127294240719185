<div class="container-fluid">
  <h1 class="mt-2">{{ 'home.title' | translate }}</h1>
  <div class="row mb-3">
    <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3 ">
      <img class="image" src="../../../../assets/images/hand-b2t2.jpg" alt="B2T2 Image" />
    </div>
    <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6  center">
      <p class="font-weight-light">
        <strong>{{ 'home.first' | translate }}</strong>
      </p>
      <p>
        <em>{{ 'home.second' | translate }}</em>
      </p>
      <p>
        <em>{{ 'home.third' | translate }}</em>
      </p>
      <p>{{ 'home.fourth' | translate }}</p>
      <p>{{ 'home.fifth' | translate }}</p>
    </div>
  </div>

  <div class="row mt-2 mb-3">
    <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4">
      <div class="row ">
        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 center-button  ">
          <a download="b2t2" target="_blank" href="/assets/app/app-release.apk">
            <img src="../../../../assets/images/google_play_store_badge_en.svg" class="android-img"
              alt="Google Play Store" />
          </a>
        </div>

        <!-- <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 center-button ">
          <img src="../../../../assets/images/app_store-en.svg" class="apple-img " alt="App Store" />
        </div> -->
      </div>
    </div>

    <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
      <div class="center-button">
        <!-- animate__repeat-3	3 -->
        <button type="button"
          class="btn btn-primary btn-lg btn-demo animate__animated animate__pulse animate__delay-1s "
          (click)="navigateToLogin()">
          {{ 'home.try' | translate }}
        </button>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3 center-start">
      <h5 class="text-uppercase mb-3">
        <a routerLink="/auth/login">{{ 'home.consortium' | translate }}</a>
      </h5>
      <p class="text-capitalize">
        <a class="consorcio" href="https://www.sis-net.it/" target="_blank">{{ 'home.sis_info' | translate }}</a>
      </p>
      <p class="text-capitalize">
        <a class="consorcio" href="https://www.tim.it/" target="_blank">{{ 'home.telecom_mobile' | translate }}</a>
      </p>

      <p class="text-capitalize">
        <a class="consorcio" href="https://www.fundacion.valenciaport.com/" target="_blank">{{
          'home.fundation' | translate
          }}</a>
      </p>
      <p class="text-capitalize">
        <a class="consorcio" href="https://www.infoport.es/" target="_blank">{{ 'home.infoport' | translate }}</a>
      </p>
    </div>

    <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3 center-start">
      <h5 class="text-uppercase mb-3">
        <a routerLink="/auth/login"> {{ 'home.project_funding' | translate }}</a>
      </h5>
      <p>{{ 'home.project_funding_desc' | translate }}</p>
    </div>

    <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 center-start">
      <img class="eit-image" src="../../../../assets/images/EITLogo.png" alt="eit" />
    </div>
  </div>

  <div class="row contact-terms-privacy">
    <div class="col-sm-12 col-md-12 col-lg-2 col-xl-2  ">
      <a routerLink="/pages/cookies-privacy">
        <p>{{ 'home.privacy_cookies' | translate }}</p>
      </a>
    </div>
    <div class="col-sm-12 col-md-12 col-lg-2 col-xl-2 ">
      <a routerLink="/pages/terms">
        <p>{{ 'home.term_of_use' | translate }}</p>
      </a>
    </div>
  </div>
</div>