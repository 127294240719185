import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ip-not-found',
  templateUrl: './ip-not-found.component.html',
  styleUrls: ['./ip-not-found.component.scss']
})
export class IpNotFoundComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
