<ng-template #loading><ip-loading [color]="'warn'"></ip-loading></ng-template>
<ng-container *ngIf="!refresh; else loading"> </ng-container>
<div class="container-fluid">
  <div class="row mt-2">
    <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
      <ip-search
        (search)="search($event); searchLength = $event.length"
        class="col-sm-12 col-md-12 col-lg-12 col-xl-12 w-100"
        [searchText]="matLabelSearch"
        [isMatLabel]="false"
        [disabled]="refresh"
        #ipSearch
      ></ip-search>

      <!-- MOSTRAR RESULTADOS BUSCADOR && FILTROS-->
      <ng-container *ngIf="!refresh && (searchLength > 0 || showFilterResults)">
        <div class="alert" role="alert" [ngClass]="metadata.count > 0 ? 'alert-success' : 'alert-danger'">
          {{ 'trackable_list.match' | translate: { match: metadata.count } }}
        </div>
      </ng-container>
    </div>

    <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4">
      <button mat-raised-button class="refresh" [disabled]="refresh" (click)="refreshData()">
        <span class="material-icons-outlined"> refresh </span>
      </button>

      <ip-filter
        (selected)="radioChange($event)"
        (disabledFilter)="disabledFilter()"
        [arrayList]="arrayList"
        #filter
        [selectedItem]="selectedItem"
        [disabled]="refresh"
      ></ip-filter>
    </div>
    <div class="col-sm-12 col-md-12 col-lg-2 col-xl-2 mt-1 ">
      <button mat-raised-button class="refresh" [disabled]="refresh" (click)="showInMap()">
        <span class="material-icons-outlined"> map </span> {{ 'dialog.show_in_map' | translate }}
      </button>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4 mb-3 mt-3 pointer " *ngFor="let entityType of pageSlice">
      <div
        [ngClass]="getIncidenciaStyle(entityType.lastTrackableEvent)"
      >
        <div class="card bg-color-gray text-primary " (click)="openDialog(entityType)">
          <div class="card-body" data-placement="bottom" title="ID: {{ entityType.ID }} ">
            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3  contenedor">
                <div
                  *ngIf="entityType.shipmentID"
                  id="imagen"
                  style="background-image: url('../../../../../assets/images/shipment.png');"
                ></div>

                <div
                  *ngIf="entityType.assetType === 'conveyor' && entityType.conveyorType === 'vessel'"
                  id="imagen"
                  style="background-image: url('../../../../../assets/images/vessel.jpg');"
                ></div>

                <div
                  *ngIf="entityType.assetType === 'conveyor' && entityType.conveyorType === 'train'"
                  id="imagen"
                  style="background-image: url('../../../../../assets/images/train.jpg');"
                ></div>

                <div
                  *ngIf="entityType.assetType === 'conveyor' && entityType.conveyorType === 'truck'"
                  id="imagen"
                  style="background-image: url('../../../../../assets/images/truck.jpg');"
                ></div>

                <div
                  *ngIf="entityType.assetType === 'stockItem'"
                  id="imagen"
                  style="background-image: url('../../../../../assets/images/stock.jpg');"
                ></div>

                <div
                  *ngIf="entityType.assetType === 'shipment' && !entityType.transportUnitType && !entityType.shipmentID"
                  id="imagen"
                  style="background-image: url('../../../../../assets/images/no-image.png');"
                ></div>

                <div
                  *ngIf="entityType.transportUnitID"
                  id="imagen"
                  style="background-image: url('../../../../../assets/images/containers.jpg');"
                ></div>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-7 col-xl-7 contenedor">
                <div class="card-body">
                  <!-- <h1 mat-dialog-title>{{ entityType.shipmentID ? entityType.shipmentID : entityType.ID | uppercase }}</h1> -->

                  <h5 class="card-title" *ngIf="entityType.shipmentID">
                    {{
                      entityType.shipmentID ? entityType.shipmentID : ('trackable_list.no_shipment_id' | translate | uppercase)
                    }}
                  </h5>
                  <h5 class="card-title" *ngIf="entityType.conveyorID">
                    {{ entityType.conveyorID ? entityType.name : ('trackable_list.no_conveyor_id' | translate | uppercase) }}
                  </h5>
                  <h5 class="card-title" *ngIf="entityType.assetType === 'stockItem'">
                    {{ entityType.stockID ? entityType.stockID : ('trackable_list.no_stock_id' | translate | uppercase) }}
                  </h5>

                  <h5 class="card-title" *ngIf="entityType.transportUnitID">
                    {{
                      entityType.transportUnitID
                        ? entityType.transportUnitID
                        : ('trackable_list.no_stock_id' | translate | uppercase)
                    }}
                  </h5>

                  <h5
                    class="card-title"
                    *ngIf="
                      !entityType.stockID && !entityType.conveyorID && !entityType.shipmentID && !entityType.transportUnitID
                    "
                  >
                    {{ 'trackable_list.no_id' | translate | uppercase }}
                  </h5>
                  <div class="row">
                    <p class="card-text col-sm-12 col-md-12 col-lg-6 col-xl-6 ">
                      {{
                        entityType.lastTrackableEvent && entityType.lastTrackableEvent.performedTime
                          ? (entityType.lastTrackableEvent.performedTime | date: 'mediumDate')
                          : '--/--/--'
                      }}
                    </p>

                    <p class="card-text col-sm-12 col-md-12 col-lg-6 col-xl-6 ">
                      {{
                        entityType.lastTrackableEvent && entityType.lastTrackableEvent.performedTime
                          ? (entityType.lastTrackableEvent.performedTime | date: 'HH:mm')
                          : '--:--'
                      }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-2 col-xl-2  contenedor ">
                <span class="material-icons-round"> double_arrow </span>
              </div>
            </div>
            <div class="row m-2">
              <div class="col-sm-6">
                <p class="card-text col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center">
                  {{ 'trackable_list.entity_status' | translate }}

                  <span class="badge bg-primary text-white ">{{
                    entityType.lastTrackableEvent && entityType.lastTrackableEvent.trackableEntityStatus
                      ? (entityType.lastTrackableEvent.trackableEntityStatus | uppercase)
                      : '------'
                  }}</span>
                </p>
              </div>
              <div class="col-sm-6">
                <p class="card-text col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center">
                  {{ 'trackable_list.event_status' | translate }}

                  <span class="badge bg-secondary text-white ">{{
                    entityType.lastTrackableEvent && entityType.lastTrackableEvent.eventType
                      ? (entityType.lastTrackableEvent.eventType | uppercase)
                      : '------'
                  }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-container *ngIf="metadata">
    <mat-paginator
      [length]="metadata.count"
      [pageSize]="20"
      [pageSizeOptions]="[2, 5, 10, 20, 50, 100]"
      (page)="OnPageChange($event)"
      [showFirstLastButtons]="true"
      [disabled]="refresh"
      #paginator
    >
    </mat-paginator>
  </ng-container>
</div>
