<mat-button-toggle-group name="toogle" aria-label="Toogle" class="m-1" #group="matButtonToggleGroup" [disabled]="disabled">
  <mat-button-toggle [matMenuTriggerFor]="menu"> <span class="material-icons-outlined "> filter_alt </span></mat-button-toggle>

  <mat-button-toggle *ngIf="selectedItem" (click)="clearSelectedItem()" [disabled]="disabled">
    <span [ngClass]="disabled ? 'text-muted' : 'text-secondary'"> {{ selectedValue | translate }} </span>
    <span [ngClass]="disabled ? 'text-muted' : 'text-secondary'" class="material-icons-outlined "> clear </span>
  </mat-button-toggle>
</mat-button-toggle-group>
<mat-menu #menu="matMenu">
  <mat-radio-group
    aria-labelledby="ip-radio-group-label"
    class="ip-radio-group"
    [(ngModel)]="selectedItem"
    [disabled]="disabled"
  >
    <mat-radio-button
      class="ip-radio-button"
      *ngFor="let item of arrayList"
      [value]="item.value"
      (change)="radioChange($event)"
    >
      {{ item.translateKey | translate }}
    </mat-radio-button>
  </mat-radio-group>
</mat-menu>
