import { Component, OnInit } from '@angular/core';
import { NbComponentStatus, NbDialogService, NbSearchService, NbToastrService } from '@nebular/theme';
import { faCoffee } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  value = '';

  faCoffee = faCoffee;

  // status: NbComponentStatus;
  // error = '0 resultados';
  // success = '1 resultado encontrado';
  constructor(private router: Router) {}

  ngOnInit(): void {}

  navigateToLogin() {
    this.router.navigate(['auth/login']);
  }
}
