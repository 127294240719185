import { NbAuthJWTToken, NbPasswordAuthStrategy } from '@nebular/auth';
import {
  logoutSettings,
  registerSettings,
  requestPasswordSettings,
  validationSettings
} from 'src/app/@theme/utils/form-settings';
import { environment } from 'src/environments/environment';
import { resetPasswordSettings, loginSettings } from '../../@theme/utils/form-settings';

export const AuthOptions = {
  strategies: [
    NbPasswordAuthStrategy.setup({
      name: 'email',
      token: {
        class: NbAuthJWTToken
        // key: 'token', // this parameter tells where to look for the token
      },
      baseEndpoint: environment.baseUrl,
      login: environment.endpoints.login,
      register: environment.endpoints.register,
      logout: environment.endpoints.logout,
      requestPass: environment.endpoints.requestPass,
      resetPass: environment.endpoints.resetPass,
      refreshToken: environment.endpoints.refreshToken
    })
  ],
  forms: {
    login: loginSettings, // no existe en VGM
    register: registerSettings,
    requestPassword: requestPasswordSettings,
    resetPassword: resetPasswordSettings, // no existe en VGM
    logout: logoutSettings,
    validation: validationSettings
  }
};
