import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  Observable, of,
} from 'rxjs';
import {
  retry,
  map,
  shareReplay,
} from 'rxjs/operators';
import { environment } from 'src/environments/environment';


import { AbstractRestApi } from './abstract-rest-api';
import { User } from '../../shared/utils/class/user';
import { Asset, AssetDefinition, AssetList } from '../../shared/utils/class/asset';



// import { User, Asset, AssetList, AssetDefinition } from '@app/shared';

@Injectable({
  providedIn: 'root',
})
export class UserRestApiService extends AbstractRestApi {

  userObservable: Observable<User>;

  constructor(
    protected http: HttpClient,
    ) {
      super(http);
   }

  public getUsers(assetList: AssetList): Observable<AssetList> {
    const query = this.generateQuery(assetList);
    return this.http.get<AssetList>(environment.baseUrl + environment.endpoints.users.endpoint + query, this.httpOptions)
            .pipe(retry(1));
  }

  public getUser(force = false): Observable<User> {
      if (!localStorage.getItem('auth_app_token')) {
        return of({
          email: '',
          disabled: true,
          roles: ['guest'],
          emailSubscriptions: [],
        });
      }
      if (this.userObservable && !force) {
        return this.userObservable;
      } else {
        this.userObservable = this.http.get<Asset>(environment.baseUrl + environment.endpoints.getProfile.endpoint, this.httpOptions)
        .pipe(
          retry(1),
          map((asset: Asset) => asset.data),
          shareReplay(),
        );
        return this.userObservable;
      }
  }

  public updateProfile(user: any): Observable<User> {
    this.userObservable = this.http.put<Asset>(environment.baseUrl + environment.endpoints.putProfile.endpoint, this.generateAsset(user),
    this.httpOptions)
    .pipe(
      retry(1),
      map((asset: Asset) => asset.data),
      shareReplay(),
    );
    return this.userObservable;
}

public getAssetDefinition(): Observable<AssetDefinition> {
    return this.http.get<AssetDefinition>(environment.baseUrl + environment.endpoints.getProfile.endpoint + '?metadata', this.httpOptions)
            .pipe(
              retry(1),
            );
  }

  public getReports(fields: string, params: any = {}): Observable<Asset> {
    if (!localStorage.getItem('auth_app_token')) {
      return of({
        metadata: {},
        data: {},
      });
    }
    let query = '';
    if (params) {
      Object.keys(params)?.forEach((key: string) => {
        query += `&${key}=${params[key]}`;
      });
    }
    return this.http.get<Asset>(
      environment.baseUrl + environment.endpoints.users.endpoint + '?metadata=' + fields + query, this.httpOptions,
    ).pipe(
      retry(1),
    );
  }

  public postUser(user: User): Observable<Asset> {
    return this.http.post<Asset>(environment.baseUrl + environment.endpoints.users.endpoint, this.generateAsset(user),
            this.httpOptions)
            .pipe(retry(1));
  }

  public putUser(user: User): Observable<Asset> {
    return this.http.put<Asset>(environment.baseUrl + environment.endpoints.users.endpoint, this.generateAsset(user),
            this.httpOptions)
            .pipe(retry(1));
  }

  public deleteUser(user: User): Observable<Asset> {
    return this.http.delete<Asset>(environment.baseUrl + environment.endpoints.users.endpoint + '/' + user._id, this.httpOptions)
            .pipe(retry(1));
  }

  public refreshToken(): Observable<Asset> {
    return this.http.get<Asset>(environment.baseUrl + environment.endpoints.refreshToken.endpoint, this.httpOptions)
            .pipe(
              retry(1),
            );
  }

  public verifyResetToken(resetToken: string): Observable<Asset> {
    return this.http.post<Asset>(environment.baseUrl + environment.endpoints.verifyResetToken.endpoint,
      {token: resetToken},
      this.httpOptions)
            .pipe(
              retry(1),
            );
  }

  public unsubscribe(unsubscribeToken: string): Observable<Asset> {
    return this.http.get<Asset>(environment.baseUrl + environment.endpoints.unsubscribe.endpoint + '?token='
      + unsubscribeToken,
      this.httpOptions)
            .pipe(
              retry(1),
            );
  }

  public action(actionToken: string): Observable<Asset> {
    return this.http.get<Asset>(environment.baseUrl + environment.endpoints.action.endpoint + '?token='
      + actionToken,
      this.httpOptions)
            .pipe(
              retry(1),
            );
  }

  public checkEmail(email: string): Observable<Asset> {
    return this.http.get<Asset>(environment.baseUrl + environment.endpoints.checkEmail.endpoint + '?email=' + email,
       this.httpOptions)
            .pipe(
              retry(1),
            );
  }

  public getEmail(userId: string): Observable<Asset> {
    return this.http.get<Asset>(environment.baseUrl + environment.endpoints.getEmail.endpoint + '?id=' + userId,
       this.httpOptions)
            .pipe(
              retry(1),
            );
  }

}
