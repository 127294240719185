// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  appUrl: '/',
  baseUrl: '/api/',
  redsysUrl: '',
  endpoints: {
    login: {
      endpoint: 'auth/login',
      method: 'POST', // 'post'
      socialLinks: [],
      defaultErrors: ['login.wrongLogin'],
      defaultMessages: ['login.loginSuccessful'],
      redirect: {
        success: '/pages/list',
        failure: null // stay on the same page
      }
    },
    register: {
      endpoint: 'auth/sign-up',
      method: 'POST', // 'post'
      defaultErrors: ['register.error'],
      defaultMessages: ['register.instructions']
    },
    logout: {
      endpoint: 'auth/logout'
    },
    requestPass: {
      endpoint: 'auth/request-password',
      defaultErrors: ['requestpwd.error'],
      defaultMessages: ['requestpwd.requestedInfo', 'requestpwd.requestedInstructions']
    },
    resetPass: {
      endpoint: 'auth/reset-password',
      defaultErrors: ['changepwd.error'],
      defaultMessages: ['changepwd.success']
    },
    refreshToken: {
      endpoint: 'auth/refresh-token',
      defaultErrors: ['changepwd.error'],
      defaultMessages: ['changepwd.refreshToken']
    },
    verifyResetToken: {
      endpoint: 'auth/verify-reset-token'
    },
    checkEmail: {
      endpoint: 'auth/checkEmail'
    },
    getEmail: {
      endpoint: 'auth/getEmail'
    },
    unsubscribe: {
      endpoint: 'auth/unsubscribe'
    },
    action: {
      endpoint: 'auth/action'
    },
    getProfile: {
      endpoint: 'auth/profile'
    },
    putProfile: {
      endpoint: 'auth/profile'
    },
    uploadFiles: {
      endpoint: 'uploadFiles'
    },
    users: {
      endpoint: 'users'
    },
    accounts: {
      endpoint: 'accounts'
    },
    company: {
      endpoint: 'companies'
    },
    entities: {
      endpoint: 'trackableEntities'
    },
    events: {
      endpoint: 'trackableEvents'
    },
    shipments: {
      endpoint: 'shipments'
    },
    stockItems: {
      endpoint: 'stockItems'
    },
    conveyors: {
      endpoint: 'conveyors'
    },
    shipUnits: {
      endpoint: 'shipUnits'
    },
    handlingUnits: {
      endpoint: 'handlingUnits'
    }
  }
};

/* login: {
  redirect: {
    success: '/dashboard/',
    failure: null, // stay on the same page
  },
},

register: {
  redirect: {
    success: '/welcome/',
    failure: null, // stay on the same page
  },
} */

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
